.advisor-bg {
  width: 100%;
  /* height: 2300px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advisor-title {
  padding: 20px 0px 30px 0px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  color: black;
}

.about-leadership-ceo1{
  height: auto; /* 将固定高度改为自动，以适应内容大小 */
  margin-top: 30px;
  padding: 20px; /* 添加内边距以确保内容不触及边缘 */
  display: flex;
  align-items: center; /* 垂直居中内容 */
  justify-content: center; /* 水平居中内容 */
  flex-direction: column; /* 垂直堆叠子元素 */
}

.leader-title {
  padding: 50px 0px 0px 0px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  color: black;
}

.advisor-container {
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 420px) {
  .advisor-bg {
    height: auto;
    width: 100%;
  }

  .advisor-title {
    padding: 20px 0px 10px 0px;
  }
  .advisor-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 420px) and (max-width: 768px) {
  .advisor-bg {
    height: auto;
    width: 100%;
  }

  .advisor-title {
    padding: 20px 0px 10px 0px;
  }
  .advisor-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
