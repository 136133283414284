.ping-logo {
    margin: auto;
    margin-bottom: 40px;
    margin-top: 80px;
}

.ping-intro-bg {
    background-color: white;
    padding-top: 140px;
    padding-bottom: 130px;
    padding-bottom: 10px;
}

.ping-grid-bg {
    margin-top: 30px;
    margin-bottom: 100px;
}

.ping-content1 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    margin-top: -15px;
}

.ping-content2 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
}

.ping-content3 {
    margin-bottom: 40px;    
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    padding-bottom: 30px;
}

.ping-content4 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: #d9d9d9;
}

.service-iam-title {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #fff;
}

.serivce-iam-link {
    font-size: 20px;
    font-family: 'NunitoSans-regular',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #1890ff;
}

.ant-col ant-col-12 gutter-row1 {
    background-color: red !important;
}


.ping-title {
    height: 400px;
}