.employee-card {
  background-color: #fafafa;
  width: 216px;
  height: 310px;
  padding: 8px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: black;
  font-family: "NunitoSans-regular", sans-serif;
}

.employee-card:hover {
  box-shadow: 0px -0.8px 6px rgba(143, 145, 144, 0.1),
    0px 6.4px 20px rgba(143, 145, 144, 0.2);
}

.employee-linkedin {
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 8px;
  // margin-left: 15px;s
}

.employee-pic {
  width: 200px;
  height: 200px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.employee-name {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.employee-title {
  font-size: 16px;
  color: black;
}

@media screen and (max-width:420px) {
  .employee-card{
    width: 160px;
    height: 250px;
  }
  .employee-name {
    font-size: 16px;
    font-weight: bold;
  }
  .employee-title {
    font-size: 12px;
  }
  .employee-pic {
    width: 150px;
    height: 150px;
  }
}
