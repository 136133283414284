.job-description {
  /* margin-top : 63px; */
  padding-bottom: 100px;
  background-color: white;
  color: black;
  text-align: left;
}
.resumeEmail {
  color: #1890ff;
}
.jb-inner {
  width: 1200px;
  margin: auto auto auto auto;
}
.title {
  padding-top: 100px;
  font-size: 40px;
  font-family: "SFProDisplay-semibold", sans-serif;
}
.subtitle {
  font-family: "NunitoSans-semibold", sans-serif;
  font-size: 23px;
  margin: 30px auto 10px 0;
}
.job-des-content {
  font-family: "NunitoSans-semibold", sans-serif;
  font-size: 16px;
  margin: 30px auto 10px 0;
  width: 1170px;
  text-align: justify;
  background-color: white;
}
.point {
  margin-left: 20px;
}
.point::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background: black;
  position: relative;
  top: -4px;
  left: -10px;
}

.jb-content {
  margin-top: 40px;
}

@media screen and (max-width: 420px) {
  .jb-inner {
    width: 100%;
    padding-left: 20px;
  }
  .job-des-content {
    width: 320px;
    font-size: 14px;
    margin: 12px auto 6px 0;
  }
  .title {
    padding-top: 100px;
    font-size: 28px;
    font-family: "SFProDisplay-semibold", sans-serif;
  }
  .subtitle {
    font-family: "NunitoSans-semibold", sans-serif;
    font-size: 22px;
    margin: 12px auto 6px 0;
  }
}


@media screen and (min-width: 420px) and (max-width: 800px) {
  .jb-inner {
    width: 100%;
    padding-left: 20px;
  }
  .job-des-content {
    width: 320px;
    font-size: 14px;
    margin: 12px auto 6px 0;
  }
  .title {
    padding-top: 100px;
    font-size: 28px;
    font-family: "SFProDisplay-semibold", sans-serif;
  }
  .subtitle {
    font-family: "NunitoSans-semibold", sans-serif;
    font-size: 22px;
    margin: 12px auto 6px 0;
  }
}

@media screen and (min-width: 420px) and (max-width: 1200px) {
  .jb-inner {
    width: 100%;
    padding-left: 20px;
  }
  .job-des-content {
    width: 320px;
    font-size: 14px;
    margin: 12px auto 6px 0;
  }
  .title {
    padding-top: 100px;
    font-size: 28px;
    font-family: "SFProDisplay-semibold", sans-serif;
  }
  .subtitle {
    font-family: "NunitoSans-semibold", sans-serif;
    font-size: 22px;
    margin: 12px auto 6px 0;
  }
}