.advantage-title {
  font-size: 28px;
  font-family: "SFProDisplay-Bold", sans-serif;
  margin-bottom: 15px;
  color: black;
}

.advantage-content {
  width: 700px;
  font-size: 18px;
  font-family: "NunitoSans-regular", sans-serif;
  margin: auto;
  color: black;
}

.adv-bg {
  background-color: #eff2f7;
  margin-top: -30px;
  padding-top: 70px;
  height: 300px;
}

.advantage-learnmore {
  margin-top: 20px;
  font-family: "NunitoSans-regular", sans-serif;
  font-size: 18px;
  line-height: 150%;
}

@media screen and (max-width: 800px) {
  .advantage-title {
    font-size: 28px;
    color: white;
    margin: auto;
  }

  .advantage-content {
    font-size: 18px;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    color: white;
  }

  .adv-bg {
    background-color: black;
    padding-top: 60px;
    height: 290px;
    width: 100%;
    min-width: 180px;
  }

  .advantage-learnmore {
    font-size: 18px;
  }
}

@media screen and (max-width: 630px) {
  .adv-bg {
    height: 380px;
  }
}

@media screen and (max-width: 420px) {
  .advantage-title {
    font-size: 20px;
    width: 270px;
    color: white;
    margin: auto;
  }

  .advantage-content {
    width: 320px;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
  }

  .adv-bg {
    background-color: black;
    padding-top: 60px;
    height: 310px;
    width: 100%;
    min-width: 180px;
  }

  .advantage-learnmore {
    font-size: 14px;
  }
}
