.certCard-container {
  width: 540px;
  height: 400px;
  margin-bottom: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 8px 8px 18px 6px rgba(65, 75, 88, 0.3)
}

.certCard-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 10px;
}

.certCard-title {
  font-size: 34px;
  font-family: "NunitoSans-bold", sans-serif;
  padding-bottom: 5px;
  margin: 15px 0 0 0;
}

.certCard-certName {
  font-size: 16px;
  font-family: "NunitoSans-regular", sans-serif;
}

.certCard-img {
  width: 85px;
}

.certCard-divider {
  width: 150px;
}

.certCard-subList {
  display: flex;
  justify-content: space-evenly;
}


@media screen and (max-width:420px)  {
  .certCard-container{
    width: 320px;
    height: 280px;
    margin-bottom: 30px;
  }
  .certCard-title {
    font-size: 24px;
    font-family: "NunitoSans-bold", sans-serif;
    padding-bottom: 4px;
    margin: 5px 0 0 0;
  }
}

@media screen and (min-width: 769px) and (max-width:1024px)  {
  .certCard-container{
    width: 480px;
    height: 360px;
    margin-bottom: 30px;
  }
  .certCard-title {
    font-size: 24px;
    font-family: "NunitoSans-bold", sans-serif;
    padding-bottom: 4px;
    margin: 5px 0 0 0;
  }
}


