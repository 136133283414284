@import url(https://fonts.googleapis.com/css?family=Work+Sans:300,600);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-bg {
  background-color: red;
}

.authright-inner {
  width: 1200px;
  margin: auto;
}


@font-face {
  font-family: 'NunitoSans-regular';
  src: url(/static/media/NunitoSans-Regular.87d92665.ttf)
}

@font-face {
  font-family: 'NunitoSans-bold';
  src: url(/static/media/NunitoSans-Bold.8268666c.ttf);
}

@font-face {
  font-family: 'NunitoSans-SemiBold';
  src: url(/static/media/NunitoSans-SemiBold.662b8665.ttf)
}

@font-face {
  font-family: "normal-italic";
  src: url(/static/media/NunitoSans-Italic.a8081512.ttf)
}

@font-face {
  font-family: "NunitoSans-Light";
  src: url(/static/media/NunitoSans-Light.7f9eed4c.ttf)
}

@font-face {
  font-family: "SFProDisplay-Semibold";
  src: url(/static/media/FontsFree-Net-SFProDisplay-Semibold.e563de5c.ttf)
}

@font-face {
  font-family: "SFProDisplay-Bold";
  src: url(/static/media/FontsFree-Net-SFProDisplay-Bold.aa9f6c10.ttf)
}

@font-face {
  font-family: "SFProDisplay-MediumItalic";
  src: url(/static/media/FontsFree-Net-SFProDisplay-MediumItalic.3b519bf4.ttf)
}

@font-face {
  font-family: "SFProDisplay-Regular";
  src: url(/static/media/FontsFree-Net-SFProDisplay-Regular.1e1c0e29.ttf)
}

@font-face {
  font-family: "SFProDisplay-SemiboldItalic";
  src: url(/static/media/FontsFree-Net-SFProDisplay-SemiboldItalic.0ec11106.ttf)
}


.light {
  font-family: "NunitoSans-Light";
}

.italic {
  font-family: "normal-italic";
}

.semibold {
  font-family: 'NunitoSans-SemiBold' !important;
}

.bold {
  font-family: "NunitoSans-bold" !important;
}

.regular {
  font-family: "NunitoSans-regular" !important;
}
.App {
  text-align: center;
  background-color: black;
}

.main-content {
  min-height: calc(100vh - 600px);
}

.footer {
  height: 600px;
}

:root {
  --background: black;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.header-bg {
  margin: 0;
  background: black;
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 17px;
  height: 80px;
}

.content {
  height: 200vh;
  background-image: url(//unsplash.it/1000/1000);
  background-color: #333;
  background-blend-mode: multiply;
  background-size: cover;
  display: grid;
  place-items: center;
}

header {
  background: var(--background);
  text-align: center;
  position: fixed;
  z-index: 999;
  width: 100%;
}

/* 控制汉堡icon */
.nav-toggle-label {
  position: absolute;
  top: 4px;
  right: 25px;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;
}

/* 汉堡三条线 */
.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
  display: block;
  background: white;
  height: 2px;
  width: 1.7em;
  border-radius: 2px;
  position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
  content: "";
  position: absolute;
}

.nav-toggle-label span::before {
  bottom: 7px;
}

.nav-toggle-label span::after {
  top: 7px;
}

nav {
  position: absolute;
  text-align: left;
  top: 70%;
  left: 0px;
  background: black;
  width: 100%;
}

nav ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

nav li {
  margin-bottom: 1em;
  margin-left: 1em;
}

nav a {
  color: #a7a7ab;
  margin-left: 0.8em;
  text-decoration: none;
  font-size: 17px;
  font-family: "NunitoSans-bold", sans-serif;
}

nav a:hover {
  color: #fff;
}

.nav-active {
  color: #fff;
}

.headerLogo {
  margin-top: 27px;
}

.wide-nav-link { 
  flex-grow: 1;  /*Change the width of nav bar font*/
  white-space: nowrap; 
}

@media screen and (min-width: 1024px) {
  .nav-toggle-label {
    display: none;
  }

  nav li {
    margin-left: 1.5em;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 769px) {
  .nav-toggle-label {
    display: none;
    margin-right: 20px;
  }

  header {
    display: grid;
    /* display: flex; */
    grid-template-columns: 1fr 0 minmax(550px, 1fr) 2fr;
  }

  nav {
    position: relative;
    text-align: left;
    transition: none;
    transform: scale(1, 1);
    top: initial;
    left: initial;
    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  nav ul {
    display: flex;
  }

  nav li {
    margin-left: 0.5em;
    margin-bottom: 10px;
  }

  nav a {
    opacity: 1;
    position: relative;
  }

  nav a::before {
    content: "";
    display: block;
    /* height: 5px; */
    background: #f0f0f0;
    position: absolute;
    top: -0.75em;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transition: transform ease-in-out 250ms;
  }

  nav a:hover::before {
    transform: scale(1, 1);
  }
}

.header-nav {
  padding-top: 20px;
  height: auto;
  background-color: black;
}


@media screen and (min-width: 1000px) {
  .headerLogo {
    margin-left: 40px;
  }

  

  .wide-nav-link { 
    flex-grow: 1;  /*Change the width of nav bar font*/
    white-space: nowrap; 
  }
}



.footer-bg {
  background-color: black;
  padding-bottom: 20px;
}

.footer-block {
  margin-left: 23%;
}

.gray-lane-footer {
  height: 1px;
  background-color: #595959;
  /* width: 1200px; */
  margin: auto;
  margin-bottom: 20px;
}

.footer-title {
  font-size: 18px;
  font-family: "NunitoSans-bold", sans-serif;
  color: #eff2f7;
  text-align: left;
  margin-bottom: 5px;
  margin-left: 13px;
}

.footer-title2 {
  font-size: 18px;
  font-family: "NunitoSans-bold", sans-serif;
  color: #eff2f7;
  text-align: left;
  margin-bottom: 5px;
  margin-left: 130px;
}

.footer-link {
  margin-bottom: 5px;
  color: #8c8c8c;
  margin-top: 5px;
  margin-left: 14px;
  font-size: 18px;
  font-family: "NunitoSans-regular", sans-serif;
  text-align: left;
  color: #8c8c8c;
}

.footer-link a {
  text-decoration: none !important;
  color: inherit;
}

.footer-link a:hover {
    color: #1890ff;
  }

.footer-content {
  font-size: 18px;
  font-family: "NunitoSans-regular", sans-serif;
  margin-bottom: 5px;
  color: #8c8c8c;
  text-align: left;
  margin-top: 5px;
  margin-left: 14px;
}

.footer-content2 {
  font-size: 18px;
  font-family: "NunitoSans-regular", sans-serif;
  margin-bottom: 5px;
  color: #8c8c8c;
  text-align: left;
  margin-top: 5px;
}

.footer-divi-img {
  float: left;
  margin-right: 10px; 
  margin-top: 4px; 
  height: 20px;
}

.inner-footer {
  width: 1200px;
  margin: auto;
}

.footer-address {
  margin-left: 76px;
}

.footer-linkedin {
  display: flex;
  margin-left: 14px;
  cursor:pointer;
}

@media screen and (max-width: 768px) {
  .footer-block {
    margin-left: 5% !important;
  }

  .footer-content {
    font-size: 14px;
    font-family: "NunitoSans-regular", sans-serif;
    margin-bottom: 5px;
    margin-right: 5px;
    color: #8c8c8c;
    text-align: left;
    margin-top: 5px;
    margin-left: 14px;
  }

  .footer-content2 {
    font-size: 14px;
    font-family: "NunitoSans-regular", sans-serif;
    margin-bottom: 5px;
    color: #8c8c8c;
    text-align: left;
    margin-top: 5px;
  }

  .footer-link {
    margin-bottom: 5px;
    color: #8c8c8c;
    margin-top: 5px;
    margin-left: 14px;
    font-size: 14px;
    font-family: "NunitoSans-regular", sans-serif;
    text-align: left;
    color: #8c8c8c;
  }
  
  .footer-title {
    font-size: 14px;
    font-family: "NunitoSans-bold", sans-serif;
    color: #eff2f7;
    text-align: left;
    margin-bottom: 5px;
    margin-left: 13px;
  }
}

.home-bg {
    background-color: black;
    /* min-width: 1250px; */
    padding-top: 80px;
}

  @media screen and (min-width: 373px) and (max-width: 590px) {
    .CustomerComments-bg {
        height: 800px;
        padding-top: 120px;
    }
    .BrandTrust-bg {
        padding-top: 140px;
    }
  }
  

  
  @media screen and (min-width: 760px) and (max-width: 770px) {
    .CustomerComments-bg {
        height: 800px;
        padding-top: 80px;
    }
    .BrandTrust-bg {
        padding-top: 10px;
    }
  }
  
  @media screen and (min-width: 771px) and (max-width: 830px) {
    
    
  }
  
  @media screen and (min-width: 831px) and (max-width: 1200px) {
    
  }
.intro-bg {
  padding-top: 30px;
  background-color: black;
  height: 800px;
  background-image: url("https://p.ipic.vip/4qtdfl.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  min-width: 1250px;
}

.intro-content {
  width: 1200px;
  margin: auto;
}

.company-name-title {
  font-size: 56px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  color: #000000;
  padding-top: 250px;
}

.company-name-content1 {
  font-family: "NunitoSans-semibold", sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 10px;
  color: #000000;
  /* text-align: right; */
  /* padding-right: 40px; */
  margin-top: -20px;
}

.company-name-content2 {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 25px;
  line-height: 150%;
  font-weight: 700px;
  color: #000000;
  margin-top: 60px;
}

.gray-lane {
  height: 1px;
  background-color: #eff2f7;
  width: 68%;
  margin: auto;
  margin-bottom: 20px;
}

.gray-lane-intro {
  height: 1px;
  background-color: #eff2f7;
  width: 330px;
  margin: auto;
  margin-bottom: 20px;
}

.intro-text {
  width: 400px;
  text-align: center;
  /* margin: auto auto auto 20%;s */
}

@media screen and (max-width: 1024px) {
  .intro-bg {
    background-image: none;
    background-color:#f0f0f0;
    height: 360px;
    min-width: 200px;
    width: 100%;
    overflow: auto;
  }

  .intro-content {
    width: 360px;
    margin: auto;
  }

  .intro-text {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .company-name-title {
    padding-top: 50px;
    margin-bottom: 20px;
    font-size: 56px;
  }
  .company-name-content1 {
    line-height: 150%;
    margin-bottom: 10px;
    font-size: 18px;
  }
  .company-name-content2 {
    font-size: 25px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 800px) {
  .intro-bg {
    background-image: none;
    background-color:#f0f0f0;
    height: 320px;
    min-width: 200px;
    width: 100%;
    overflow: auto;
  }

  .intro-content {
    width: 360px;
    margin: auto;
  }

  .intro-text {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .company-name-title {
    padding-top: 50px;
    margin-bottom: 20px;
    font-size: 35px;
  }
  .company-name-content1 {
    line-height: 150%;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .company-name-content2 {
    font-size: 20px;
    margin-top: 30px;
  }
}


@media screen and (max-width: 420px) {
  .intro-bg {
    background-image: none;
    background-color:#f0f0f0;
    height: 320px;
    min-width: 200px;
    width: 100%;
    overflow: auto;
  }

  .intro-content {
    width: 360px;
    margin: auto;
  }

  .intro-text {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .company-name-title {
    padding-top: 50px;
    margin-bottom: 20px;
    font-size: 35px;
  }
  .company-name-content1 {
    line-height: 150%;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .company-name-content2 {
    font-size: 20px;
    margin-top: 30px;
  }
}

.advantage-title {
  font-size: 28px;
  font-family: "SFProDisplay-Bold", sans-serif;
  margin-bottom: 15px;
  color: black;
}

.advantage-content {
  width: 700px;
  font-size: 18px;
  font-family: "NunitoSans-regular", sans-serif;
  margin: auto;
  color: black;
}

.adv-bg {
  background-color: #eff2f7;
  margin-top: -30px;
  padding-top: 70px;
  height: 300px;
}

.advantage-learnmore {
  margin-top: 20px;
  font-family: "NunitoSans-regular", sans-serif;
  font-size: 18px;
  line-height: 150%;
}

@media screen and (max-width: 800px) {
  .advantage-title {
    font-size: 28px;
    color: white;
    margin: auto;
  }

  .advantage-content {
    font-size: 18px;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    color: white;
  }

  .adv-bg {
    background-color: black;
    padding-top: 60px;
    height: 290px;
    width: 100%;
    min-width: 180px;
  }

  .advantage-learnmore {
    font-size: 18px;
  }
}

@media screen and (max-width: 630px) {
  .adv-bg {
    height: 380px;
  }
}

@media screen and (max-width: 420px) {
  .advantage-title {
    font-size: 20px;
    width: 270px;
    color: white;
    margin: auto;
  }

  .advantage-content {
    width: 320px;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
  }

  .adv-bg {
    background-color: black;
    padding-top: 60px;
    height: 310px;
    width: 100%;
    min-width: 180px;
  }

  .advantage-learnmore {
    font-size: 14px;
  }
}

.clients-bg2 {
  min-width: 1200px;
  border: none;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  /* position: relative; */
  height: 160px;
  background-color: black;
  margin-top: 65px;
}

.clients-bg {
  display: inline-block;
  position: relative;
  padding-right: 0px;
  animation: logo 35s linear infinite;
  white-space: nowrap;
  margin-top: -74px;
  /* min-width: 1200px; */
}

.hsbc-logo {
  margin-top: -20px;
  width: 120px;
  height: 160px;
  margin-left: 25px;
  margin-right: 25px;
}

.blue-cross-logo {
  margin-top: -28px;
  width: 73px;
  height: 100px;
  margin-left: 25px;
  margin-right: 25px;
}

.deloitte-logo {
  margin-top: -20px;
  width: 120px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.toyota-logo {
  margin-top: -20px;
  width: 140px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.homeDepot-logo {
  margin-top: -20px;
  width: 65px;
  height: 160px;
  margin-left: 25px;
  margin-right: 25px;
}

.macy-logo {
  margin-top: -20px;
  width: 180px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.equifax-logo {
  margin-top: -20px;
  width: 120px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.mohawk-logo {
  margin-top: -20px;
  width: 120px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.lowes-logo {
  margin-top: -20px;
  width: 120px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.pearson-logo {
  margin-top: -20px;
  width: 120px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}
@keyframes logo {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 1024px) {
  .clients-bg2 {
    min-width: 1024px;
    border: none;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
    /* position: relative; */
    height: 140px;
    background-color: black;
    margin-top: 45px;
  }
}


@media screen and (max-width: 800px) {
  .clients-bg2 {
    display: none;
  }
}

.customerComments-bg {
  background-color: black;
}

.customerComments-title {
  margin: auto;
  padding-top: 70px;
  padding-bottom: 20px;
  font-size: 40px;
  font-family: "SFProDisplay-Bold", sans-serif;
  color: #f5f5f5;
  width: 800px;
}

.customerComments-section{
  margin:auto;
}

.customerComments-content {
  margin: auto;
  padding-bottom: 70px;
  font-size: 18px;
  font-family: "NunitoSans-regular", sans-serif;
  color: #f5f5f5;
  width: 700px;
}

@media screen and (max-width: 400px) {
  .customerComments-bg {
    background-color: white;
    width: 100%;
  }

  .customerComments-title {
    margin: auto;
    padding-top: 60px;
    padding-bottom: 20px;
    font-size: 20px;
    font-family: "SFProDisplay-Bold", sans-serif;
    color: black;
    width: 360px;
  }

  .customerComments-content {
    margin: auto;
    padding-bottom: 60px;
    font-size: 14px;
    font-family: "NunitoSans-regular", sans-serif;
    color: black;
    width: 360px;
  }
}

.customerComments-forgerockCeo-img {
  border-radius: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.customerComments-customer-title {
  margin-top: -40px;
  text-align: left;
  margin-left: 250px;
  font-size: 20px;
  /* font-family: 'NunitoSans-regular'; */
  font-family: "SFProDisplay-Semibold", sans-serif;
  color: #262626;
}

.customerComments-customer-title2 {
  text-align: left;
  margin-left: 250px;
  font-size: 16px;
  /* font-family: 'NunitoSans-regular'; */
  font-family: "SFProDisplay-regular", sans-serif;
  color: #595959;
}

.customer-comments-content {
  width: 500px;
  padding-top: 20px;
  text-align: left;
  font-size: 25px;
  /* font-family: 'NunitoSans-regular'; */
  font-family: "SFProDisplay-MediumItalic", sans-serif;
  color: black;
}

.comments-bg {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  height: 200px;
  width: 550px;
  margin-top: 80px;
  padding-left: -120px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  border: 2px solid transparent;
  background-clip: padding-box;
  box-shadow: 10px 10px 10px rgba(45, 55, 68, 0.3);
  line-height: 1.5;
}

.comments-bg2 {
  background: url(/static/media/case-mohawk-img.14a2ab57.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 20%;
  height: 200px;
  width: 550px;
  border-radius: 20px;
}

.container-customersComments {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 580px !important;
  /* flex-wrap: wrap; */
  z-index: 1;
  /* background: #161623 !important; */
  background: url("https://images.unsplash.com/photo-1536514498073-50e69d39c6cf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.container-customersComments .card-customersComments {
  position: relative;
  width: 280px;
  height: 400px;
  margin: 20px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.container-customersComments
  .card-customersComments
  .content-customersComments {
  padding: 20px;
  text-align: center;
  opacity: 1;
}

.container-customersComments
  .card-customersComments
  .content-customersComments
  h2 {
  position: absolute;
  top: -80px;
  right: 30px;
  font-size: 8em;
  color: rgba(255, 255, 255, 0.05);
  pointer-events: none;
}

.container-customersComments
  .card-customersComments
  .content-customersComments
  h3 {
  font-size: 1.8em;
  color: #000;
  z-index: 1;
}

.container-customersComments
  .card-customersComments
  .content-customersComments
  h4 {
  color: #000;
  z-index: 1;
}

.container-customersComments
  .card-customersComments
  .content-customersComments
  p {
  font-size: 1em;
  color: #141414;
  font-weight: 300;
  font-family: sans-serif;
}

.container-customersComments
  .card-customersComments
  .content-customersComments
  a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  margin-top: 15px;
  background: #fff;
  color: #000;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 500;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.comment-paragraph {
  margin-top: 30px;
}

.comments-logo {
  width: 130px;
  height: auto;
  margin-top: -7px;
}

.comments-logo-homedepot {
  width: 180px;
  margin-top: -10px;
  margin-bottom: 20px;
}

.equifax-comments-scope {
  width: 80%;
  margin: auto;
}

.comments-logo-equifax {
  width: 260px;
  margin-bottom: -10px;
}

.comments-logo-mohawk {
  width: 160px;
  margin-top: 15px;
  margin-bottom: 40px;
}

.tiltTest {
  padding: 0px !important;
  margin: 0px !important;
  background: white !important;
}


@media screen and (max-width: 1200px) {
  .container-customersComments .card-customersComments {
    width: 210px;
    height: 300px;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h3 {
    margin-top: -10px;
    font-size: 1.2em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h4 {
    font-size: 0.7em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    p {
    font-size: 0.66em;
    color: #141414;
    font-weight: 300;
    font-family: sans-serif;
  }

  .comments-logo {
    width: 87px;
    height: auto;
    margin-top: -10px;
  }

  .comments-logo-homedepot {
    width: 121px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  
  .comments-logo-equifax {
    width: 174px;
    margin-top: -35px;
    margin-bottom: -10px;
  }
  
  .comments-logo-mohawk {
    width: 107px;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .comment-paragraph {
    margin-top: 10px;
  }

}


@media screen and (max-width: 850px) {
  .customerComments-bg {
    background-color: white;
  }
  .container-customersComments .card-customersComments {
    width: 165px;
    height: 230px;
    margin: auto;
    margin-top: 20px;
  }

  .container-customersComments {
    height: 400px !important;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h3 {
    margin-top: -10px;
    font-size: 1.2em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h4 {
    font-size: 0.7em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    p {
    font-size: 0.66em;
    color: #141414;
    font-weight: 300;
    font-family: sans-serif;
  }

  .comments-logo {
    width: 87px;
    height: auto;
    margin-top: -10px;
  }

  .comments-logo-homedepot {
    width: 121px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  
  .comments-logo-equifax {
    width: 174px;
    margin-top: -35px;
    margin-bottom: -10px;
  }
  
  .comments-logo-mohawk {
    width: 107px;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .comment-paragraph {
    margin-top: 10px;
  }

  .customerComments-title {
    font-size: 28px;
    width: 100%;
    color: black;
  }

  .customerComments-content {
    margin: auto;
    padding-bottom: 70px;
    font-size: 18px;
    width: 90%;
    color: black;
  }
}


@media screen and (max-width: 440px) {
  .container-customersComments .card-customersComments {
    width: 165px;
    height: 230px;
    margin: auto;
    margin-top: 20px;
  }

  .container-customersComments {
    height: 580px !important;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h3 {
    margin-top: -10px;
    font-size: 1.2em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h4 {
    font-size: 0.7em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    p {
    font-size: 0.66em;
    color: #141414;
    font-weight: 300;
    font-family: sans-serif;
  }

  .comments-logo {
    width: 87px;
    height: auto;
    margin-top: -10px;
  }

  .comments-logo-homedepot {
    width: 121px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  
  .comments-logo-equifax {
    width: 174px;
    margin-top: -35px;
    margin-bottom: -10px;
  }
  
  .comments-logo-mohawk {
    width: 107px;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .comment-paragraph {
    margin-top: 10px;
  }

  .customerComments-title {
    font-size: 20px;
    width: 100%;
  }

  .customerComments-content {
    margin: auto;
    padding-bottom: 70px;
    font-size: 13px;
    width: 90%;
  }

}




.trust-bg {
  background-color: black;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 100px;
}

.trust-bg2 {
  margin: auto;
}

.turst-title {
  padding-top: 50px;
  color: #fff;
  text-align: center;
  font-size: 50px;
  font-family: "SFProDisplay-Semibold", sans-serif;
}

.turst-title2 {
  color: #fff;
  text-align: center;
  font-size: 50px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  margin-bottom: 50px;
}

.trust-grid-bg {
  display: flex;
  justify-content: center;
}

.trust-color-block {
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
  border-radius: 5%;
  height: 360px;
  width: 260px;
  background: linear-gradient(130deg, #ff7a18, #af002d 41.07%, #319197 76.05%);
}

.trust-black-block {
  margin: auto;
  background-color: black;
  height: 350px;
  width: 250px;
  border-radius: 5%;
}

.trust-color-block-title {
  text-align: center;
  padding-top: 30px;
  /* margin-left: 20px; */
  margin-bottom: -10px;
  font-family: "SFProDisplay-Bold", sans-serif;
  padding-bottom: -5px;
  font-style: normal;
  font-size: clamp(1.8rem, calc(1.8rem + 0.2vw), 3rem);
  font-weight: 800;
  color: white;
}

.trust-color-block-title2 {
  margin-bottom: -12px;
  text-align: center;
  /* margin-left: 20px; */
  font-family: "SFProDisplay-Bold", sans-serif;
  font-style: normal;
  font-size: clamp(1.8rem, calc(1.8rem + 0.2vw), 3rem);
  font-weight: 800;
  color: white;
}

.trust-color-block-title3 {
  text-align: center;
  /* margin-left: 20px; */
  font-family: "SFProDisplay-Bold", sans-serif;
  font-style: normal;
  font-size: clamp(1.8rem, calc(1.8rem + 0.2vw), 3rem);
  font-weight: 800;
  color: white;
}

.trust-color-block-title4 {
  margin-bottom: -12px;
  text-align: center;
  /* margin-left: 17px; */
  font-family: "SFProDisplay-Bold", sans-serif;
  font-style: normal;
  font-size: clamp(1.8rem, calc(1.8rem + 0.2vw), 3rem);
  font-weight: 800;
  color: white;
}

.trust-color-block-served {
  padding-top: 70px;
  text-align: right;
  margin-right: 20px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  font-size: clamp(12px, calc(0.8rem + 0.25vw), 20px);
  font-weight: 300;
  line-height: 1.7;
  color: white;
}

.trust-color-block-served2 {
  text-align: left;
  margin-left: 20px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  font-size: clamp(12px, calc(0.8rem + 0.25vw), 20px);
  font-weight: 300;
  line-height: 1;
  color: #1890ff;
}

.trust-forgerock {
  max-width: 110px;
  max-height: 110px;
  margin-bottom: 34px;
  margin-top: 60px;
  border-radius: 5px;
}

.trust-ping {
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 25px;
  margin-top: 60px;
  border-radius: 5px;
}

@media screen and (max-width: 800px) {
  .trust-color-block {
    margin-left: 50px;
    margin-right: 50px;
    height: 234px;
    width: 170px;
  }

  .trust-black-block {
    height: 224px;
    width: 160px;
    border-radius: 5%;
  }

  .trust-ping {
    width: 70px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .trust-forgerock {
    width: 74px;
    margin-top: 45px;
    margin-bottom: 22px;
  }

  .trust-color-block-title2 {
    margin-bottom: -12px;
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }
  
  .trust-color-block-title3 {
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }

  .trust-grid-bg {
    margin:auto
  }

  .turst-title {
    padding-top: 50px;
    color: #fff;
    text-align: center;
    font-size: 28px;
    font-family: "SFProDisplay-Bold", sans-serif;
  }
}


@media screen and (max-width: 460px) {
  .trust-color-block {
    margin-left: 9px;
    margin-right: 10px;
    height: 234px;
    width: 170px;
  }

  .trust-black-block {
    height: 224px;
    width: 160px;
    border-radius: 5%;
  }

  .trust-ping {
    width: 70px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .trust-forgerock {
    width: 74px;
    margin-top: 45px;
    margin-bottom: 22px;
  }

  .trust-color-block-title2 {
    margin-bottom: -12px;
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }
  
  .trust-color-block-title3 {
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }

  .trust-grid-bg {
    margin:auto
  }

  .turst-title {
    padding-top: 50px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: "SFProDisplay-Bold", sans-serif;
  }
}


@media screen and (max-width: 420px) {
  .trust-color-block {
    margin-left: 9px;
    margin-right: 10px;
    height: 234px;
    width: 170px;
  }

  .trust-black-block {
    height: 224px;
    width: 160px;
    border-radius: 5%;
  }

  .trust-ping {
    width: 70px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .trust-forgerock {
    width: 74px;
    margin-top: 45px;
    margin-bottom: 22px;
  }

  .trust-color-block-title2 {
    margin-bottom: -12px;
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }
  
  .trust-color-block-title3 {
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }

  .trust-grid-bg {
    margin:auto
  }

  .turst-title {
    padding-top: 50px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: "SFProDisplay-Bold", sans-serif;
  }
}
.business-bg2 {
  background-color: #fafafa;
  margin: auto;
  padding-top: 80px;
}

.businessOutcome-intro {
  height: 1000px;
  width: 1200px;
  margin: auto;
}

.businessOutcome-title1 {
  padding-top: 100px;
  font-size: 80px;
  font-family: "SFProDisplay-Bold", sans-serif;
  text-align: center;
  color: black;
}

.businessOutcome-title2 {
  position: relative;
  margin-top: 120px !important;
  font-size: 60px;
  font-family: "SFProDisplay-Bold", sans-serif;
  text-align: center;
  margin: 2% 10%;
  color: black;
  z-index: 1;
}

.businessOutcome-underline-img {
  position: relative;
  margin-top: -160px;
  margin-left: -350px;
}

.businessOutcome-card {
  width: 1200px;
  margin: auto;
  margin-top: -100px;
}

.business-bg {
  background-color: black;
}

.customer-logo-list {
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: 20px;
  margin-top: 100px;
}

.customer-logo {
  max-width: 250px;
  max-height: 165px;
  margin-bottom: 50px;
}

svg,
#customer-logo {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 375px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 10px;
    margin-top: 10px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 360px;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 35px;
    padding-top: 60px;
  }

  .businessOutcome-title2 {
    margin-top: 45px !important;
    font-size: 25px;
  }

  .businessOutcome-underline-img {
    width: 70px !important;
    margin-left: 55px;
    bottom: 500px;
  }
}


@media screen and (min-width: 376px) and (max-width: 390px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 10px;
    margin-top: 10px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 360px;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 35px;
    padding-top: 60px;
  }

  .businessOutcome-title2 {
    font-size: 25px;
    margin-top: 45px !important;
  }

  .businessOutcome-underline-img {
    width: 70px !important;
    margin-left: 55px;
    bottom: 678px;
  }
}


@media screen and (min-width: 411px) and (max-width: 429px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 10px;
    margin-top: 10px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 360px;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 35px;
    padding-top: 60px;
  }

  .businessOutcome-title2 {
    font-size: 25px;
    margin-top: 45px !important;
  }

  .businessOutcome-underline-img {
    width: 70px !important;
    margin-left: 55px;
    bottom: 730px;
  }
}

@media screen and (min-width: 430px) and (max-width: 459px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 10px;
    margin-top: 10px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 360px;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 35px;
    padding-top: 60px;
  }

  .businessOutcome-title2 {
    margin-top: 45px !important;
    font-size: 25px;
  }

  .businessOutcome-underline-img {
    width: 70px !important;
    margin-left: 53px;
    bottom: 765px;
  }
}

@media screen and (min-width: 760px) and (max-width: 770px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 10px;
    margin-top: 20px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 80%;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 60px;
    padding-top: 100px;
  }

  .businessOutcome-title2 {
    font-size: 50px;
    margin-top: 45px !important;
  }

  .businessOutcome-underline-img {
    width: 140px !important;
    bottom: 820px;
    margin-left: 245px;
  }
}

@media screen and (min-width: 771px) and (max-width: 830px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 10px;
    margin-top: 20px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 80%;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 60px;
    padding-top: 100px;
  }

  .businessOutcome-title2 {
    font-size: 50px;
    margin-top: 45px !important;
  }

  .businessOutcome-underline-img {
    width: 140px !important;
    bottom: 973px;
    margin-left: 235px;
  }
}

@media screen and (min-width: 831px) and (max-width: 1200px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 10px;
    margin-top: 40px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 80%;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 60px;
    padding-top: 100px;
  }

  .businessOutcome-title2 {
    font-size: 50px;
  }

  .businessOutcome-underline-img {
    width: 140px !important;
    bottom: 1085px;
    margin-left: 88px;
  }
}
.solution-IAM-bg {
  text-align: left;
}

.forgerock-conent-bg {
}

.solution-iam-title {
  margin-top: 60px;
  padding-top: 50px;
  font-family: "NunitoSans-extraBold", sans-serif;
  font-size: 20px;
  line-height: 150%;
  color: #595959;
  margin-bottom: 15px;
}

.solution-forgerock-title {
  font-family: "NunitoSans-extraBold", sans-serif;
  font-size: 38px;
  line-height: 150%;
  color: black;
  margin-bottom: 15px;
}

.partner-topics-title {
  font-family: "NunitoSans-extraBold", sans-serif;
  font-size: 30px;
  line-height: 150%;
  color: black;
  margin-bottom: 20px;
}

.partner-forgerock-intro {
  font-family: "NunitoSans-regular", sans-serif;
  font-size: 18px;
  line-height: 150%;
  color: #1f1f1f;
}

.partner-forgerock-intro1 {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 20px;
  line-height: 150%;
  color: #1f1f1f;
}

.solution-forgerock-intro2 {
  font-family: "NunitoSans-regular", sans-serif;
  font-size: 25px !important;
  line-height: 150%;
  color: #1f1f1f;
  margin-bottom: 30px;
}

.solution-forgerock-logo {
  /* margin-top: -28px; */
  float: right;
}

.solution-gray-lane {
  height: 1px;
  background-color: #d9d9d9;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
}

.solution-gray-lane2 {
  height: 1px;
  background-color: #d9d9d9;
  width: 455px;
  margin-top: 15px;
  margin-bottom: 30px;
}

@media (max-width: 800px) {
  .partner-topics-title {
    font-size: 25px;
  }
  .solution-IAM-bg {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 420px) {
  .partner-topics-title {
    font-size: 20px;
  }
  .solution-IAM-bg {
    padding-left: 20px;
    padding-right: 20px;
  }
  .partner-forgerock-intro1 {
    font-size: 15px;
    margin-right: 0px;
  }
  .partner-forgerock-intro {
    font-size: 15px;
    padding-left: 10px;
  }
  .solution-gray-lane2 {
    width: 300px;
  }

}

.ping-logo {
    margin: auto;
    margin-bottom: 40px;
    margin-top: 80px;
}

.ping-intro-bg {
    background-color: white;
    padding-top: 40px;
    margin-bottom: 30px;
    padding-bottom: 10px;
}

.ping-grid-bg {
    margin-top: -30px;
    margin-bottom: -30px;
}

.ping-content1 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    margin-top: -15px;
}

.ping-content2 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
}

.ping-content3 {
    margin-bottom: 40px;    
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    padding-bottom: 30px;
}

.ping-content4 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: #d9d9d9;
}

.solution-iam-title2 {
    padding-top: 40px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-ping-logo {
    /* float: left; */
    margin-top: 70px;
    margin-left: 200px;;
}

.service-iam-title {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #fff;
}

.serivce-iam-link {
    font-size: 20px;
    font-family: 'NunitoSans-regular',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #1890ff;
}

.ant-col ant-col-12 gutter-row1 {
    background-color: red !important;
}


.ping-title {
    height: 400px;
}

.solution-ping-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 370px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.ping-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 50px;
}

.ping-cert-logo2 {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 80px;
}

.ping-cert-logo3 {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 110px;
}

.ping-cert-logo4 {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 140px;
}

.ping-cert-logo5 {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 180px;
}
.career-bg {
    padding-top: 80px;
}

.inner {
    max-width: 1200px;
    margin: auto;
}

.odd-row {
    text-align: left;
}

.even-row {
    text-align: left;
}

.solution-row {
    /* cursor: pointer; */
}

.gap-lane {
    height: 1px;
    background-color: #484747;
    width: 95%;
    margin: 15px auto 20px auto;
} 

.forgerock-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 60px;
}

.fr-cert {
    margin-top: 40px;
    text-align: left;
    margin-bottom: 70px;
}
.ping-cert-row {
    margin-top: 30px;
    margin-bottom: 120px;
}

.cert-unit {
    /* width: 550px !important;
    text-align: left; */
}

.solution-row-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 48px;
    line-height: 150%;
    color: black;
    margin-bottom: 15px;
    padding-top: 70px;
    padding-right: 10px;
}
.solution-content-text {
    font-size: 19px;
    
}

.learn-more-extra-space {
    padding-left: 30px;
}

.learn-more-extend {
    margin-top: 50px;
    color: rgb(18, 141, 218);
    cursor: pointer;
    font-size: 19px;
    margin-left: 70px;
}

.solution-logo-gray-lane {
    height: 1px;
    width: 570px;
    background-color: rgb(230, 230, 230);
    margin-bottom: 0px;
}

.parnter-ping-logo {
    margin-top: 70px;
    margin-left: 200px;
    width: 120px;
}

.solution-ping-logo-gray-lane {
    height: 1px;
    width: 570px;
    background-color: rgb(230, 230, 230);
    margin-bottom: 0px;
    margin-top: 65px;
}

.solution-fg-logo {
    /* float: left; */
    margin-top: 30px;
    margin-left: 130px;
    width: 250px;
    /* margin: auto; */
}

.partner-title {
    font-size: 80px;
    font-family: "SFProDisplay-Bold", sans-serif;
    text-align: center;
    color: black;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 70px;
}

.parnter-subTitle {
    margin: auto;
    text-align: center;
    font-family: "SFProDisplay-semiBold", sans-serif;
    color: black;
    font-size: 60px;
    margin-bottom: 100px;
}

.crt-img {
    padding-right: 50px
}

@media (max-width: 930px) {
    .partner-title {
        font-size: 60px;
    }
    .parnter-subTitle {
        font-size: 50px;
    }
    .solution-fg-logo {
        margin-left: 60px;
        width: 45%
    }
    .solution-logo-gray-lane {
        width: 70%;
        margin-left: 3%;
    }
    .solution-row-title {
        font-size: 35px;
        width: 70%;
    }
    .solution-content-text {
        font-size: 17px;
        width: 320px;
    }
    .crt-img {
        padding-left: 0px;
        padding-right: 5px;
    }
    .fg-cert {
        width: 70px;
        margin-left: 10px;
        margin-right: 0px;
    }
    .parnter-ping-logo  {
        margin-left: 15%;
        width: 160px
    }
    .solution-ping-logo-gray-lane {
        width: 80%;
        margin-left: 15px;
    }
    .ping-cert {
        width: 80px;
        margin-left: 10px;
        margin-right: 0px;
    }
}

@media (max-width: 420px) {
    .partner-title {
        font-size: 40px;
        margin-bottom: 10px;
        padding-top: 50px;
    }
    .parnter-subTitle {
        font-size:30px; 
        margin-bottom: 20px;
    }
    .solution-row-title {
        display: none;
    }
    .solution-content-text {
        display: none;
    }
    .solution-logo-gray-lane {
        width: 100%;
    }
    .solution-ping-logo-gray-lane {
        width: 100%;
    }
    .solution-fg-logo {
        margin-left: auto;
        width: 220px
    }
    .parnter-ping-logo  {
        margin-left: 15%;
        width: 160px
    }
    .learn-more-extend {
        margin-left: 10px;
    }
    .crt-img {
        padding-left: 25px;
        padding-right: 25px;
    }
    .fg-cert {
        width: 80px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .ping-cert {
        width: 80px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .learn-more-extra-space {
        padding-left: 0px;
    }
}
.expandCard-grid {
  padding-top: 100px;
}

.about-leadership {
  margin-top: -200px;
  /* background-color: #434343; */
  background-color: #000;
  display: flex;
  justify-content: center;
}

.about-certification {
  /* margin-top: 60px; */
  margin-top: 250px;
}

.about-layout-bg {
  padding-top: 80px;
  background-color: white;
}


@media screen and (max-width: 768px) {
  .about-leadership {
    display: none;
    margin-top: 0px;
  }
  .about-certification {
    margin-top: 60px;
    /* margin-top: -200px; */
  }
}

.employee-card{background-color:#fafafa;width:216px;height:310px;padding:8px;margin-bottom:20px;display:flex;flex-direction:column;align-items:center;border-radius:5px;color:#000;font-family:"NunitoSans-regular",sans-serif}.employee-card:hover{box-shadow:0px -0.8px 6px rgba(143,145,144,.1),0px 6.4px 20px rgba(143,145,144,.2)}.employee-linkedin{cursor:pointer;margin-bottom:5px;margin-top:8px}.employee-pic{width:200px;height:200px;border-radius:5px;margin-bottom:5px}.employee-name{font-size:20px;font-weight:bold;color:#000}.employee-title{font-size:16px;color:#000}@media screen and (max-width: 420px){.employee-card{width:160px;height:250px}.employee-name{font-size:16px;font-weight:bold}.employee-title{font-size:12px}.employee-pic{width:150px;height:150px}}
.leadership-bg {
  padding-top: 90px;
  /* background-color: #000; */
  /* background-size: 100%;
  background-position: 100% 120%;
  background-repeat: no-repeat; */
  /* min-width: 1200px; */
  height: auto;
  width: 1200px;
  /* margin-left: -100px; */
}

.leadership-container {
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-gap: 10px;
}

/* .about-leadership-content {
  margin-top: 10px;
  margin-bottom: 60px;
  font-family: "SFProDisplay-Bold", sans-serif;
  font-size: 20px;
  line-height: 150%;
  color: #ed2939;
  background: -webkit-linear-gradient(380deg, #ed2939 15%, #330867 95%); */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.about-leadership-title {
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  color: white;
  margin-bottom: 40px;
}

@media screen and (max-width: 420px) {
  .leadership-bg {
    width: 360px;
  }
  .leadership-container {
    grid-template-columns: repeat(auto-fill, 170px);
    grid-gap: 10px;
  }
}


@media screen and (max-width: 768px) and (min-width:421px) {
  .leadership-bg {
    width: 700px;
  }
}

.advisorCard-container {
  width: 1200px;
  height: 240px;
  /* border:1px solid black; */
  box-shadow: 0px 2px 2.9px rgba(0, 142, 131, 0.05),
    0px 0px 5px rgba(3, 79, 73, 0.1);
  border-radius: 5px;
  display: flex;
  margin: 20px auto;
  padding-right: 15px;
}

.advisorCard-img {
  width: 240px;
  height: 240px;
  margin-right: 20px;
  border-radius: 5px 0px 0px 5px;
}
.advisorCard-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.advisorCard-name {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 24px;
  font-family: "NunitoSans-bold", sans-serif;
}

.advisorCard-title {
  text-align: left;
  font-size: 16px;
  font-family: "NunitoSans-bold", sans-serif;
}

.advisor-linkedin {
  cursor: pointer;
  height: 32px;
  margin-top: 5px;
}
.advisorCard-description {
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
  font-family: "NunitoSans-regular", sans-serif;
}

@media screen and (max-width: 420px) {
  .advisorCard-container {
    width: 164px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    padding: 8px;
  }

  .advisorCard-img {
    width: 150px;
    height: 150px;
    margin: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .advisorCard-name {
    font-size: 16px;
  }

  .advisorCard-title {
    font-size: 13px;
  }

  .advisor-linkedin {
    height: 20px;
    margin-top: 0px;
  }
  .advisorCard-description {
    font-size: 11px;
  }
  .advisorCard-description {
    display: none;
  }
}

@media screen and (min-width: 421px) and (max-width: 768px) {
  .advisorCard-container {
    width: 260px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    padding: 8px;
  }

  .advisorCard-img {
    width: 240px;
    height: 240px;
    margin: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .advisorCard-name {
    font-size: 20px;
  }

  .advisorCard-title {
    font-size: 16px;
  }

  .advisor-linkedin {
    height: 28px;
    margin-top: 0px;
  }
  .advisorCard-description {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .advisorCard-container {
    width: 80%;
    height: auto;
    margin: 10px auto;
    padding: 8px;
  }

  .advisorCard-img {
    width: 240px;
    height: 240px;
    margin: auto;
    margin-right:10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .advisorCard-name {
    font-size: 20px;
  }

  .advisorCard-title {
    font-size: 16px;
  }

  .advisor-linkedin {
    height: 28px;
    margin-top: 0px;
  }
  .advisorCard-description {
    font-size: 12px;
  }
}


.leadCard-container {
  width: 1200px;
  height: 280px;
  /* border:1px solid black; */
  /* box-shadow: 0px 2px 2.9px rgba(143, 145, 144, 0.05),
    0px 0px 5px rgba(143, 145, 144, 0.1); */
  border-radius: 5px;
  display: flex;
  margin: 60px auto;
  padding-right: 10px;
  background-color: white;
}

/* .leadCard-container:hover {
    box-shadow: 0px -0.8px 6px rgba(143, 145, 144, 0.1),
      0px 6.4px 20px rgba(143, 145, 144, 0.2);
  } */
.leadCard-img {
  width: 270px;
  margin-right: 20px;
  border-radius: 5px;
}
.leadCard-info {
  /* height: 380px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}
.leadCard-name {
  display: flex;
  justify-content: space-between;
  font-size: 26px;
  font-family: "NunitoSans-bold", sans-serif;
  color: black;
}
.leadCard-title {
  text-align: left;
  font-size: 18px;
  font-family: "NunitoSans-bold", sans-serif;
  color: black;
}
.leadCard-linkedin {
  cursor: pointer;
  height: 35px;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
}
.leadCard-description {
  text-align: left;
  font-size: 15px;
  margin-top: 10px;
  font-family: "NunitoSans-regular", sans-serif;
  color: black;
}

@media screen and (max-width: 420px) {
  .leadCard-container {
    width: auto;
    height: auto;
    margin: 10px auto;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .leadCard-img {
    width: 200px;
    height: 200px;
    margin-right: 0px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .leadCard-name {
    font-size: 15px;
  }
  .leadCard-title {
    font-size: 13px;
  }
  .leadCard-linkedin {
    height: 22px;
    margin-right: 0px;
    margin-left: 50px;
  }
  .leadCard-description {
    font-size: 11px;
    margin-top: 10px;
    display: none;
  }
}

@media screen and (min-width: 420px) and (max-width: 768px){
  .leadCard-container {
    width: auto;
    height: auto;
    margin: 10px auto;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .leadCard-img {
    width: 260px;
    height: 260px;
    margin-right: 0px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .leadCard-name {
    font-size: 20px;
  }
  .leadCard-title {
    font-size: 16px;
  }
  .leadCard-linkedin {
    height: 28px;
    margin-right: 0px;
    margin-left: 50px;
  }
  .leadCard-description {
    font-size: 11px;
    margin-top: 10px;
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px){
  .leadCard-container {
    width: 82%;
    height: auto;
    margin: 10px auto;
    padding: 8px;
  }
  .leadCard-img {
    width: 32%;
    height: 32%;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .leadCard-name {
    font-size: 20px;
  }
  .leadCard-title {
    font-size: 16px;
  }
  .leadCard-linkedin {
    height: 28px;
    margin-right: 0px;
    margin-left: 50px;
  }
  .leadCard-description {
    font-size: 11.5px;
    margin-top: 10px;
  }
}

.advisor-bg {
  width: 100%;
  /* height: 2300px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advisor-title {
  padding: 20px 0px 30px 0px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  color: black;
}

.about-leadership-ceo1{
  height: auto; /* 将固定高度改为自动，以适应内容大小 */
  margin-top: 30px;
  padding: 20px; /* 添加内边距以确保内容不触及边缘 */
  display: flex;
  align-items: center; /* 垂直居中内容 */
  justify-content: center; /* 水平居中内容 */
  flex-direction: column; /* 垂直堆叠子元素 */
}

.leader-title {
  padding: 50px 0px 0px 0px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  color: black;
}

.advisor-container {
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 420px) {
  .advisor-bg {
    height: auto;
    width: 100%;
  }

  .advisor-title {
    padding: 20px 0px 10px 0px;
  }
  .advisor-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 420px) and (max-width: 768px) {
  .advisor-bg {
    height: auto;
    width: 100%;
  }

  .advisor-title {
    padding: 20px 0px 10px 0px;
  }
  .advisor-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.about-intro-bg {
  margin-bottom: 40px;
}

.career-company-title {
  padding-top: 40px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  color: #000;
}

.career-company-title {
  padding-top: 70px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  color: #000;
}

.career-company-title2 {
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  /* color: white; */
}

.about-company-title2 {
  padding-top: 140px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  /* color: white; */
}

.about-values-title {
  padding-top: 65px;
  padding-bottom: 45px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  color: white;
}

.about-intro-block {
  height: 500px;
  background-color: white;
}

.about-mission-subtitle {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 20px;
  font-weight: 800;
}

.about-intro-block2 {
  color: black;
  background-color: #ffffff;
  padding-bottom: 80px;
}

.about-intro-block3 {
  height: auto;
  background-color: #000;
  padding-bottom: 50px;
}

.career-company-intro {
  padding-top: 20px;
  font-size: 20px;
  width: 700px;
  font-family: "NunitoSans-regular", sans-serif;
  margin: auto;
  color: black;
}

.about-values {
  font-size: 20px;
  width: 900px;
  font-family: "NunitoSans-regular", sans-serif;
  margin: auto;
  color: white;
}

.career-company-content1 {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 18px;
  line-height: 150%;
  padding-top: 50px;
  /* margin-bottom: 10px; */
  color: #000;
}

.career-company-content2 {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 18px;
  line-height: 150%;
  color: #000;
}

.gray-lane {
  height: 1px;
  background-color: #eff2f7;
  width: 68%;
  margin: auto;
  margin-bottom: 20px;
}

.about-img1 {
  padding-bottom: 100px;
}

.about-color-block {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 70px;
  margin-left: 120px;
  margin-right: 20px;
  border-radius: 5%;
  height: 360px;
  width: 260px;
  background: linear-gradient(130deg, #ff7a18, #af002d 41.07%, #319197 76.05%);
}

.about-black-block {
  margin: auto;
  background-color: #fff;
  height: 350px;
  width: 250px;
  border-radius: 5%;
  color: #000;
}

.about-color-block-title {
  text-align: left;
  padding-top: 70px;
  margin-left: 20px;
  margin-bottom: 20px;
  font-family: "SFProDisplay-Bold", sans-serif;
  font-style: normal;
  font-size: clamp(1.8rem, calc(1.8rem + 0.2vw), 3rem);
  font-weight: 800;
}

.about-color-block-served {
  padding-top: 20px;
  text-align: left;
  margin-left: 20px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  font-size: clamp(12px, calc(0.8rem + 0.25vw), 20px);
  font-weight: 300;
  line-height: 1.7;
  color: #434343;
}

.about-color-block-served2 {
  text-align: left;
  margin-left: 20px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  font-size: clamp(12px, calc(0.8rem + 0.25vw), 20px);
  font-weight: 300;
  line-height: 1.7;
  color: #000;
}

.about-whoWeAre-title1 {
  padding-top: 60px;
  padding-bottom: 30px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  width: 700px;
  margin: auto;
  color: #000;
  font-size: 40px;
}

.about-whoWeAre-title2 {
  font-family: "SFProDisplay-Semibold", sans-serif;
  color: #000;
  font-size: 70px;
}

.about-whoWeAre-content {
  padding-top: 20px;
  font-size: 22px;
  width: 790px;
  font-family: "NunitoSans-regular", sans-serif;
  margin: auto;
  color: black;
}

.value-description {
  text-align: center;
  width: 850px;
  margin-top: 20px;
  color: rgb(108, 227, 227);
  height: 70px;
  background-color: #000;
  
}
.vale-description-text {
    overflow: auto;
    animation: anim 1.2s;
}
@keyframes anim { 
	0% {opacity: 0;}
	100%{opacity:1;
}
}

.selected-value {
  fill: rgb(108, 227, 227) !important;
  color: rgb(108, 227, 227);
  font-family: "NunitoSans-bold", sans-serif;
}

.unselected-value {
  fill: white;
  color: white;
}

.value-img {
  width: 80px;
  margin: auto;
}

.value-area {
  width: 135px;
  height: 135px;
  padding-top: 26px;
  margin: auto;
}
.value-halo {
  background: radial-gradient(
    closest-side circle,
    rgba(255, 255, 255, 0.509),
    black
  );
}

.value-preview {
  padding: auto;
  margin-bottom: 40px;
}

.about-color-block-served2-t1 {
  color: #1890ff;
}

.about-color-block-served2-t2 {
  color: #f5222d;
}


@media (min-width:400px) and (max-width: 800px) {
  .about-color-block {
    margin: 50px auto auto auto;
    height: 300px;
  }
  .about-black-block {
    height: 290px;
  }
  .about-whoWeAre-title1 {
    margin-top: 10px;
    width: 290px;
  }

  .about-intro-block {
    height: 450px;
    background-color: white;
  }
  
  .about-mission-subtitle {
    font-family: "NunitoSans-bold", sans-serif;
    font-size: 22px;
    font-weight: 800;
  }

  .career-company-intro {
    width: 80%;
    font-size: 15px;
  }
  .about-company-title2 {
    font-size: 18px;
    padding-top: 40px;
  }
  .about-color-block-title {
    font-size: 25px;
  }
  .about-whoWeAre-content {
    font-size: 20px;
    width: 320px;
  }

  .about-values-title {
    padding-top: 35px;
    padding-bottom: 5px;
  }

  .about-values {
    font-size: 16px;
    width: 100%;
  }
  .value-img {
    width: 50px;
    margin: auto;
  }
  .value-area {
    width: 70px;
    height: 70px;
    padding-top: 26px;
    margin: auto;
  }
  .value-description {
    width: 75%;
    margin-top: 0px;
  }
  .about-intro-block3{
    margin-bottom:30px;
  }
}

@media (max-width: 440px) {
  .about-intro-block {
    height: 650px;
    background-color: white;
  }
}

@media (max-width: 400px) {
  .about-color-block {
    margin: 50px auto auto auto;
    height: 300px;
  }
  .about-black-block {
    height: 290px;
  }
  .about-whoWeAre-title1 {
    margin-top: 10px;
    width: 290px;
  }

  .career-company-intro {
    width: 80%;
    font-size: 15px;
  }
  .about-company-title2 {
    font-size: 18px;
    padding-top: 40px;
  }
  .about-color-block-title {
    font-size: 25px;
  }
  .about-whoWeAre-content {
    font-size: 14px;
    width: 320px;
  }

  .about-values-title {
    padding-top: 35px;
    padding-bottom: 5px;
  }

  .about-values {
    font-size: 16px;
    width: 100%;
  }
  .value-img {
    width: 50px;
    margin: auto;
  }
  .value-area {
    width: 70px;
    height: 70px;
    padding-top: 26px;
    margin: auto;
  }
  .value-description {
    width: 75%;
    margin-top: 0px;
  }
  .about-intro-block3{
    margin-bottom:30px;
  }
}

.subCertCard-number {
  font-size: 60px;
  font-weight: 500;
  font-family: "NunitoSans-bold", sans-serif;
  color: #919293;
  /* color: #919293; */
  margin-bottom: 5px;
}

.subCertCard-certName {
  width: 155px;
  font-family: "NunitoSans-regular", sans-serif;
  font-size: 14px;
}

.subCertCard-img{
  width: 80px;
  margin-bottom: 20px;
  cursor: pointer;
}

.subCertCard-okta-img{
  height: 100px;
  cursor: pointer;
}

@media screen and (max-width:420px)  {
  .subCertCard-number{
    font-size: 40px;
  }
  .subCertCard-img {
    width: 40px;
    margin-bottom: 5px;
  }
  .subCertCard-okta-img{
    height: 50px;
  }
  .subCertCard-certName {
    width: 90px;
    font-size: 11px;
  }
}
.certCard-container {
  width: 540px;
  height: 400px;
  margin-bottom: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 8px 8px 18px 6px rgba(65, 75, 88, 0.3)
}

.certCard-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 10px;
}

.certCard-title {
  font-size: 34px;
  font-family: "NunitoSans-bold", sans-serif;
  padding-bottom: 5px;
  margin: 15px 0 0 0;
}

.certCard-certName {
  font-size: 16px;
  font-family: "NunitoSans-regular", sans-serif;
}

.certCard-img {
  width: 85px;
}

.certCard-divider {
  width: 150px;
}

.certCard-subList {
  display: flex;
  justify-content: space-evenly;
}


@media screen and (max-width:420px)  {
  .certCard-container{
    width: 320px;
    height: 280px;
    margin-bottom: 30px;
  }
  .certCard-title {
    font-size: 24px;
    font-family: "NunitoSans-bold", sans-serif;
    padding-bottom: 4px;
    margin: 5px 0 0 0;
  }
}

@media screen and (min-width: 769px) and (max-width:1024px)  {
  .certCard-container{
    width: 480px;
    height: 360px;
    margin-bottom: 30px;
  }
  .certCard-title {
    font-size: 24px;
    font-family: "NunitoSans-bold", sans-serif;
    padding-bottom: 4px;
    margin: 5px 0 0 0;
  }
}



.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.about-certification {
  width: 100%;
  padding-top:5px;
}

.certification-bg {
  /* margin-top: 400px; */
  height: auto;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.certification-title {
  padding: 0px 0px 50px 0px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  color: black;
}

.certification-cards {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;
  align-items: center;
  /* grid-template-columns: 50% 50%;
  grid-template-rows: 33.3% 33.3% 33.3%; */
}


@media screen and (max-width:768px) and (min-width:421px) {
  .certification-bg {
    background-color: black;
    /* padding-top: -400px; */
  }
  .certification-cards{
    width: 700px;
  }
  .certification-title {
    color: white;
    margin-top: 40px;
  }
}

@media screen and (max-width:420px)  {
  .certification-bg {
    background-color: black;
    /* padding-top: -400px; */
  }
  .certification-cards{
    width: 380px;
  }
  .certification-title {
    padding: 50px 0px 50px 0px;
    font-size: 40px;
    font-family: "NunitoSans-bold", sans-serif;
    color: white;
  }
}

@media screen and (max-width:1024px) and (min-width:769px) {
  .certification-bg {
    /* padding-top: 400px; */
    background-color: black;
  }
  .certification-cards{
    width: 100%;
  }
  .certification-title {
    color: white;
    margin-top: 40px;
  }
}


@media screen and (min-width:770px) {
  .certification-bg {
    /* margin-top: 350px; */
  }
}

.career-bg {
    background-color: white;
    padding-bottom: 100px;
}
.careers-iam-logo {
  margin-top: 100px;
}

.careers-title {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 38px;
  line-height: 150%;
  color: black;
  margin-bottom: 25px;
  margin-top: 60px;
}

.careers-position-title {
  font-family: "NunitoSans-regular", sans-serif;
  font-size: 18px;
  line-height: 150%;
  color: #595959;
}

.unifiedcontrol-card-title2 {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 18px;
  line-height: 150%;
}

.careers-gray-lane {
  height: 1px;
  background-color: #d9d9d9;
  width: 45%;
  margin-top: 15px;
  margin-bottom: 20px;
}

@media screen and (max-width: 400px) {
  .careers-gray-lane {
    width: 65%;
  }
}

.IAM-bg {
    background-color: black;
    margin-top: 40px;
}

.IAM-Intro-bg {
    background-color: white;
    padding-bottom: 30px;
    width: 1200px
}

.IAM-grid-bg {
    margin-top: 10px;
}

.service-card {
    background: rgb(76 76 76);
    padding: 60px 0px 0px 0px;
    border-radius: 10px;
    width: 335px;
    height: 500px;
    margin: auto 10px auto 10px;
}

.servicesCate-authright-logo {
    margin-top: 50px;
    margin-bottom: 30px;
}



.forgerock-content2 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
}

.forgerock-content3 {
    margin-bottom: 40px;    
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    margin-bottom: 0px;
}

.servicesCate-content4 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:17px;
    line-height: 150%;
    color: #d9d9d9;
    width: 300px;
    margin: 10px auto auto auto;
}

.service-card-title {
    font-size: 23px;
    font-family: 'SFProDisplay-bold',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #fff;
    padding-bottom: 15px;
}

.service-iam-title {
    font-size: 20px !important;
    font-family: 'SFProDisplay-bold',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #fff !;
    padding-bottom: 15px;
}

.service-iam-title2 {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    padding-bottom: 15px;
    color: #fff;
}

.service-iam-title3 {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    color: #fff;
    padding-top: 15px;
}

.serivce-iam-link {
    font-size: 20px;
    font-family: 'NunitoSans-regular',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #1890ff;
}

.service-title {
    color: white;
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: 0px auto 80px auto;
    padding-top: 50px;
}

/* .ant-col ant-col-12 gutter-row1 {
    background-color: red !important;
} */


.forgerock-title {
    height: 400px;
}

.service-img {
    margin-bottom: 24px;
}

.service-integration-card-img {
    margin-top: -24px;
    /* margin-bottom: 5psx; */
}

.service-card-support-img {
    margin-bottom: 24px;
}

@media (max-width: 1100px) {

    .about-color-block{
        margin-left: auto;
    }
    .about-company-title2 {
        padding-top: 50px
    }
}
@media (max-width: 400px) {
    .IAM-bg {
        padding-bottom: 40px;
    }
    .service-card {
        height: 400px;
        padding-top: 40px;
        width: 305px;
    }
    .service-title {
        font-size: 15px;
        padding-top: 30px;
        margin-bottom: 30px;
    }
    .service-card-support-img {
        width: 50px
    }
    .service-integration-card-img {
        width: 80px
    }
    .service-card-title {
        font-size: 18px;
    }
    .servicesCate-content4 {
        font-size: 14px;
        width: 80%

    }
}
:root {
    --clr-neutral-900: #141414;
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-accent-400: hsl(142, 90%, 61%);
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.card-bg {
    margin-top: 60px;
    min-height: 80vh;
    place-items: center;
    line-height: 1.6;
    /* background: black; */
    text-align: left;
    max-width: 1600px;
    margin: auto;
    /* background-color: black; */
}
.card {
    color: var(--clr-neutral-100);
    background-size: cover;
    padding: 10rem 0 0;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 500ms ease;
    width: 380px;
    margin: auto 10px auto 10px;
}

.card:hover,
.card:focus-within {
    transform: scale(1.05);
}

.card-content {
    --padding: 1.5rem;
    padding: 0 0 1.5rem 1.5rem;
    background: linear-gradient(
        /* hsl(0 0% 0% / 0.5), */
        hsl(0 0% 0% / 0.8),
        hsl(20 0% 0% / 0.40) 60%,
        hsl(100 0% 0% / 1) 
    );
} 

.card-title {
    /* margin-top : -50px; */
    position: relative;
    width: -webkit-max-content;
    width: max-content;
    color: var(--clr-neutral-100);
} 

.card-title::after {
    content: "";
    position: absolute;
    height: 4px;
    left: calc(var(--padding) * -1);
    bottom: -2px;
    width: calc(100% + var(--padding));
    background: var(--clr-accent-400);
    transform-origin: left;
    transition: transform 500ms ease;
} 

.card:hover .card-title::after,
.card:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-body {
    color: rgb(255 255 255 / 0.85);
    margin-top: 7px;
    margin-bottom: 7px;
    height: 70px;
}

@media (hover) {
    .card-content {
        transform: translateY(65%);
        transition: transform 500ms ease;
    }

    .card:hover .card-content,
    .card:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-content > *:not(.card-title) {
        opacity: 0;
        transition: opacity 200ms linear;
    }

    .card:hover .card-content > *:not(.card-title),
    .card:focus-within .card-content > *:not(.card-title) {
        opacity: 1;
        transition-delay: 1000ms;
    }

    .card-title::after {
        transform: scaleX(0);
    }
}

.button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: var(--clr-neutral-900);
    background-color: var(--clr-accent-400);
    padding: 0.5em 1.25em;
    border-radius: 0.25em;
}

.button:hover,
.button:focus {
    background-color: var(--clr-neutral-100);

}

.card-grid-case-study-title {
    padding-top: 50px;
    color: #fff;
    text-align: center;
    font-size: 50px;
    font-family: 'SFProDisplay-semibold',sans-serif;
}

.card-grid-title {
    padding-top: 20px;
    color: #f0f0f0;
    text-align: center;
    font-size: 20px;
    font-family: 'SFProDisplay-regular',sans-serif;
    padding-bottom: 50px;
}

p{
    margin-bottom: 0px !important;
}

@media (max-width: 420px) {
    .card-bg {
        margin-top:0px
    }
    .card-grid-case-study-title {
        font-size: 20px;
    }
    .card-grid-title {
        font-size: 13px;
    }
    .card {
        width:305px;
    }
    .card-title {
        font-size: 15px;
        padding-bottom:7px;
        padding-top: 10px;
    }
    .card-body {
        width: 100%
    }
    .button  {
        width: 85px;
        font-size: 10px;
    }
}
/* .businessOutcome-card {
	min-width: 1250px;
	margin-top: -100px;
} */

.service-bg {
	padding-top: 80px;
}

.businessOutcome-card-bg {
	margin: 0 auto 0 auto;
	padding-bottom: 100px;
	background-color: black;
}

.service-banner {
	background-color: white;
	padding-top: 70px;
	padding-bottom: 90px;
}

.service-inner {
	/* max-width: 1600px; */
	margin-bottom: 50px;
}

.servicesCate-authright-logo {
	width: 25%;
}

.servicesCate-content1 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    margin-top: -10px;
    color: black;
    width: 50%;
	max-width: 800px;
    margin-left: auto;
	margin-right: auto;
}

@media (max-width: 420px) {
	.servicesCate-authright-logo {
		display: none;
	}
	.service-banner {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.servicesCate-content1 {
		font-size: 15px;
		width: 90%
	}
	.businessOutcome-card-bg {
		max-width: 400px;
		background-color: black;
	}
}


.hsbc-bg {
    background-color: white;
    padding-top: 100px;
    color: black;
    margin: auto;
}

.case-hsbc-img {
    float: left;
    margin-left: 50px;
    margin-top: 25px;
}

.case-hsbc-logo-img {
    margin-top: 50px;
}

.caseStudies-title {
    margin-top: 15px;
    font-size: 35px;
    /* font-family: 'NunitoSans-regular'; */
    font-family: 'SFProDisplay-Semibold',sans-serif;
    color: #000;
}

.caseStudies-title2 {
    font-size: 35px;
    /* font-family: 'NunitoSans-regular'; */
    font-family: 'SFProDisplay-Semibold',sans-serif;
    margin-bottom: 30px;
    color: #000;
}

.caseStudies-content {
    font-size: 20px;
    font-family: 'NunitoSans-regular',sans-serif;
    color: #434343;
    padding-bottom: 70px;
}

.caseStudies-about-title {
    margin-top: 25px;
    padding-top: 30px;
    text-align: left;
    margin-left: 50px;
    font-size: 30px;
    /* font-family: 'NunitoSans-regular'; */
    font-family: 'SFProDisplay-Semibold',sans-serif;
    color: #000;
    padding-top: 70px;
}

.caseStudies-about-content {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 15px;
    padding-bottom: 30px;
    text-align: left;
    font-size: 20px;
    font-family: 'SFProDisplay-Regular',sans-serif;
    color: #434343;
    max-width: 500px;
}

.gray-lane-case {
    height: 30px;
    width: 2px;
    background-color: #595959;
    /* width: 1200px; */
    float: left;
    margin-left: 50px;
    margin-top: 10px;
}

.case-about-bg {
    background-color: #fafafa;
}

.caseStudies-data {
    margin-top: 80px;
    margin-left: 130px;
    font-family: 'SFProDisplay-Semibold',sans-serif;
    font-size: 20px;
}

.caseStudies-data2 {
    font-family: 'SFProDisplay-Regular',sans-serif;
    margin-left: 130px;
    font-size: 20px;
}

.caseStudies-data3 {
    margin-top: 80px;
    margin-left: 180px;
    font-family: 'SFProDisplay-Semibold',sans-serif;
    font-size: 20px;
}

.caseStudies-data4 {
    font-family: 'SFProDisplay-Regular',sans-serif;
    margin-left: 180px;
    font-size: 20px;
}

.case-authright-img {
    margin-top: 120px;
}

.case-result-bg {
    padding-bottom: 80px;
}
.case-solution-tool {
    margin-right: 130px;
}

.case-mark-block {
    text-align: left;
    font-size: 28px;
    height: 100%;
    margin-left: 100px;
    margin-bottom: 55px;
    width: 160px
}

.case-mark-number {
    color: #cf1322;
    font-family: 'SFProDisplay-Semibold',sans-serif;
}
.case-mark-text {
    font-family: 'SFProDisplay-Regular',sans-serif;
}

@media (max-width: 400px) {
    .caseStudies-title {
        font-size: 25px;
    }
    .caseStudies-title2 {
        font-size: 25px;
    }
    .caseStudies-content {
        font-size: 15px;
        width: 80%;
        margin: auto;
        padding-bottom: 20px;
    }
    .caseStudies-about-title {
        padding-top: 20px;
    }
    .case-hsbc-img {
        display: none;
    }
    .case-solution-tool {
        display: none;
    }
    .case-mark-block {
        font-size: 18px;
        width: 100px;
        margin-left: 50px;
        margin-top: -45px;
    }
}
.IAM-bg {
    background-color: black;
    margin-top: 40px;
}

.forgerock-serice-banner {
    background-color: white;
    padding-bottom: 170px;
    padding-top: 100px;
}

.IAM-grid-bg {
    margin-top: 10px;
}

.forgerock-content1 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    margin-top: -35px;
    color: black;
}

.forgerock-content2 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
}

.forgerock-content3 {
    margin-bottom: 40px;    
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    margin-bottom: 0px;
}

.forgerock-content4 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: #d9d9d9;
}

.service-iam-title {
    font-size: 40px !important;
    font-family: 'SFProDisplay-bold',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #fff !;
    padding-bottom: 15px;
}

.service-iam-title2 {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    padding-bottom: 15px;
    color: #fff;
}

.service-iam-title3 {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    color: #fff;
    padding-top: 15px;
}

.serivce-iam-link {
    font-size: 20px;
    font-family: 'NunitoSans-regular',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #1890ff;
}



.forgerock-title {
    height: 400px;
}

.service-img {
    margin-bottom: 14px;
}

.service-forgerock-integration-img {
    margin-top: -25px;
    margin-bottom: -10px;
}

.service-forgerock-support-img {
    margin-bottom: 17px;
}
.ping-logo {
    margin: auto;
    margin-bottom: 40px;
    margin-top: 80px;
}

.ping-intro-bg {
    background-color: white;
    padding-top: 140px;
    padding-bottom: 130px;
    padding-bottom: 10px;
}

.ping-grid-bg {
    margin-top: 30px;
    margin-bottom: 100px;
}

.ping-content1 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    margin-top: -15px;
}

.ping-content2 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
}

.ping-content3 {
    margin-bottom: 40px;    
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    padding-bottom: 30px;
}

.ping-content4 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: #d9d9d9;
}

.service-iam-title {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #fff;
}

.serivce-iam-link {
    font-size: 20px;
    font-family: 'NunitoSans-regular',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #1890ff;
}

.ant-col ant-col-12 gutter-row1 {
    background-color: red !important;
}


.ping-title {
    height: 400px;
}
.solution-IAM-bg {
    text-align: left;
}


.forgerock-conent-bg {
}

.solution-iam-title {
    margin-top: 60px;
    padding-top: 50px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-forgerock-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:38px;
    line-height: 150%;
    color: black;
    margin-bottom: 15px;
}

.solution-topics-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:30px;
    line-height: 150%;
    color: black;
    margin-bottom: 20px;
}

.solution-forgerock-intro {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #1f1f1f;
}

.solution-forgerock-intro1 {
    font-family: 'NunitoSans-bold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
}

.managedService-intro2 {
    width: 850px;
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.solution-forgerock-logo {
    /* margin-top: -28px; */
    float: right;
}

.solution-gray-lane {
    height: 1px;
    background-color: #d9d9d9;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
} 

.managedService-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 850px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.forgerock-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 60px;
}
.managedService-bg {
    color: white;
    font-size: white;
    background-color: red;
}
.managedService-bg {
    color: white;
    font-size: white;
    background-color: red;
}
.solution-IAM-bg {
    text-align: left;
}


.forgerock-conent-bg {
}

.solution-iam-title {
    margin-top: 60px;
    padding-top: 50px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-forgerock-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:38px;
    line-height: 150%;
    color: black;
    margin-bottom: 15px;
}

.solution-topics-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:30px;
    line-height: 150%;
    color: black;
    margin-bottom: 20px;
}

.solution-forgerock-intro {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #1f1f1f;
}

.solution-forgerock-intro1 {
    font-family: 'NunitoSans-bold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
}

.managedService-intro2 {
    width: 850px;
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.solution-forgerock-logo {
    float: right;
}

.solution-gray-lane {
    height: 1px;
    background-color: #d9d9d9;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
} 

.managedService-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 850px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.forgerock-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 60px;
}
.solution-IAM-bg {
    text-align: left;
}


.forgerock-conent-bg {
    /* background-color: #fafafa; */
}

.solution-iam-title {
    margin-top: 60px;
    padding-top: 50px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-forgerock-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:38px;
    line-height: 150%;
    color: black;
    margin-bottom: 15px;
}

.solution-topics-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:30px;
    line-height: 150%;
    color: black;
    margin-bottom: 20px;
}

.solution-forgerock-intro {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #1f1f1f;
}

.solution-forgerock-intro1 {
    font-family: 'NunitoSans-bold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
}

.managedService-intro2 {
    width: 850px;
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.solution-forgerock-logo {
    /* margin-top: -28px; */
    float: right;
}

.solution-gray-lane {
    height: 1px;
    background-color: #d9d9d9;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
} 

.managedService-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 850px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.forgerock-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 60px;
}
.solution-IAM-bg {
    text-align: left;
}

/* .solution-IAM-Intro-bg {
    margin-left: 10%;
    margin-right: 10%;
} */

.forgerock-conent-bg {
    /* background-color: #fafafa; */
}

.solution-iam-title {
    margin-top: 60px;
    padding-top: 50px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-forgerock-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:38px;
    line-height: 150%;
    color: black;
    margin-bottom: 15px;
}

.solution-topics-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:30px;
    line-height: 150%;
    color: black;
    margin-bottom: 20px;
}

.solution-forgerock-intro {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #1f1f1f;
}

.solution-forgerock-intro1 {
    font-family: 'NunitoSans-bold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
}

.managedService-intro2 {
    width: 850px;
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.solution-forgerock-logo {
    /* margin-top: -28px; */
    float: right;
}

.solution-gray-lane {
    height: 1px;
    background-color: #d9d9d9;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
} 

.managedService-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 850px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.forgerock-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 60px;
}
.solution-IAM-bg {
    text-align: left;
}

.forgerock-conent-bg {
}

.solution-iam-title {
    margin-top: 60px;
    padding-top: 50px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-forgerock-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:38px;
    line-height: 150%;
    color: black;
    margin-bottom: 15px;
}

.solution-topics-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:30px;
    line-height: 150%;
    color: black;
    margin-bottom: 20px;
}

.solution-forgerock-intro {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #1f1f1f;
}

.solution-forgerock-intro1 {
    font-family: 'NunitoSans-bold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
}

.managedService-intro2 {
    width: 850px;
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.solution-forgerock-logo {
    /* margin-top: -28px; */
    float: right;
}

.solution-gray-lane {
    height: 1px;
    background-color: #d9d9d9;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
} 

.managedService-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 850px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.forgerock-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 60px;
}
.solution-IAM-bg {
    text-align: left;
}

/* .solution-IAM-Intro-bg {
    margin-left: 10%;
    margin-right: 10%;
} */

.forgerock-conent-bg {
    /* background-color: #fafafa; */
}

.solution-iam-title {
    margin-top: 60px;
    padding-top: 50px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-forgerock-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:38px;
    line-height: 150%;
    color: black;
    margin-bottom: 15px;
}

.solution-topics-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:30px;
    line-height: 150%;
    color: black;
    margin-bottom: 20px;
}

.solution-forgerock-intro {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #1f1f1f;
}

.solution-forgerock-intro1 {
    font-family: 'NunitoSans-bold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
}

.managedService-intro2 {
    width: 850px;
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.solution-forgerock-logo {
    /* margin-top: -28px; */
    float: right;
}

.solution-gray-lane {
    height: 1px;
    background-color: #d9d9d9;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
} 

.managedService-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 850px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.forgerock-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 60px;
}
.solution-IAM-bg {
    text-align: left;
}

/* .solution-IAM-Intro-bg {
    margin-left: 10%;
    margin-right: 10%;
} */

.forgerock-conent-bg {
    /* background-color: #fafafa; */
}

.solution-iam-title {
    margin-top: 60px;
    padding-top: 50px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-forgerock-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:38px;
    line-height: 150%;
    color: black;
    margin-bottom: 15px;
}

.solution-topics-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:30px;
    line-height: 150%;
    color: black;
    margin-bottom: 20px;
}

.solution-forgerock-intro {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #1f1f1f;
}

.solution-forgerock-intro1 {
    font-family: 'NunitoSans-bold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
}

.managedService-intro2 {
    width: 850px;
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.solution-forgerock-logo {
    /* margin-top: -28px; */
    float: right;
}

.solution-gray-lane {
    height: 1px;
    background-color: #d9d9d9;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
} 

.managedService-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 850px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.forgerock-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 60px;
}
.solution-IAM-bg {
    text-align: left;
}

/* .solution-IAM-Intro-bg {
    margin-left: 10%;
    margin-right: 10%;
} */

.forgerock-conent-bg {
    /* background-color: #fafafa; */
}

.solution-iam-title {
    margin-top: 60px;
    padding-top: 50px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-forgerock-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:38px;
    line-height: 150%;
    color: black;
    margin-bottom: 15px;
}

.solution-topics-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:30px;
    line-height: 150%;
    color: black;
    margin-bottom: 20px;
}

.solution-forgerock-intro {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #1f1f1f;
}

.solution-forgerock-intro1 {
    font-family: 'NunitoSans-bold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
}

.managedService-intro2 {
    width: 850px;
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.solution-forgerock-logo {
    /* margin-top: -28px; */
    float: right;
}

.solution-gray-lane {
    height: 1px;
    background-color: #d9d9d9;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
} 

.managedService-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 850px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.forgerock-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 60px;
}
.solution-IAM-bg {
    text-align: left;
}

/* .solution-IAM-Intro-bg {
    margin-left: 10%;
    margin-right: 10%;
} */

.forgerock-conent-bg {
    /* background-color: #fafafa; */
}

.solution-iam-title {
    margin-top: 60px;
    padding-top: 50px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-forgerock-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:38px;
    line-height: 150%;
    color: black;
    margin-bottom: 15px;
}

.solution-topics-title {
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size:30px;
    line-height: 150%;
    color: black;
    margin-bottom: 20px;
}

.solution-forgerock-intro {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 18px;
    line-height: 150%;
    color: #1f1f1f;
}

.solution-forgerock-intro1 {
    font-family: 'NunitoSans-bold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
}

.managedService-intro2 {
    width: 850px;
    font-family: 'NunitoSans-regular',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.solution-forgerock-logo {
    /* margin-top: -28px; */
    float: right;
}

.solution-gray-lane {
    height: 1px;
    background-color: #d9d9d9;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
} 

.managedService-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 850px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.forgerock-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 60px;
}
.job-description {
  /* margin-top : 63px; */
  padding-bottom: 100px;
  background-color: white;
  color: black;
  text-align: left;
}
.resumeEmail {
  color: #1890ff;
}
.jb-inner {
  width: 1200px;
  margin: auto auto auto auto;
}
.title {
  padding-top: 100px;
  font-size: 40px;
  font-family: "SFProDisplay-semibold", sans-serif;
}
.subtitle {
  font-family: "NunitoSans-semibold", sans-serif;
  font-size: 23px;
  margin: 30px auto 10px 0;
}
.job-des-content {
  font-family: "NunitoSans-semibold", sans-serif;
  font-size: 16px;
  margin: 30px auto 10px 0;
  width: 1170px;
  text-align: justify;
  background-color: white;
}
.point {
  margin-left: 20px;
}
.point::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background: black;
  position: relative;
  top: -4px;
  left: -10px;
}

.jb-content {
  margin-top: 40px;
}

@media screen and (max-width: 420px) {
  .jb-inner {
    width: 100%;
    padding-left: 20px;
  }
  .job-des-content {
    width: 320px;
    font-size: 14px;
    margin: 12px auto 6px 0;
  }
  .title {
    padding-top: 100px;
    font-size: 28px;
    font-family: "SFProDisplay-semibold", sans-serif;
  }
  .subtitle {
    font-family: "NunitoSans-semibold", sans-serif;
    font-size: 22px;
    margin: 12px auto 6px 0;
  }
}


@media screen and (min-width: 420px) and (max-width: 800px) {
  .jb-inner {
    width: 100%;
    padding-left: 20px;
  }
  .job-des-content {
    width: 320px;
    font-size: 14px;
    margin: 12px auto 6px 0;
  }
  .title {
    padding-top: 100px;
    font-size: 28px;
    font-family: "SFProDisplay-semibold", sans-serif;
  }
  .subtitle {
    font-family: "NunitoSans-semibold", sans-serif;
    font-size: 22px;
    margin: 12px auto 6px 0;
  }
}

@media screen and (min-width: 420px) and (max-width: 1200px) {
  .jb-inner {
    width: 100%;
    padding-left: 20px;
  }
  .job-des-content {
    width: 320px;
    font-size: 14px;
    margin: 12px auto 6px 0;
  }
  .title {
    padding-top: 100px;
    font-size: 28px;
    font-family: "SFProDisplay-semibold", sans-serif;
  }
  .subtitle {
    font-family: "NunitoSans-semibold", sans-serif;
    font-size: 22px;
    margin: 12px auto 6px 0;
  }
}
