.expandCard-grid {
  padding-top: 100px;
}

.about-leadership {
  margin-top: -200px;
  /* background-color: #434343; */
  background-color: #000;
  display: flex;
  justify-content: center;
}

.about-certification {
  /* margin-top: 60px; */
  margin-top: 250px;
}

.about-layout-bg {
  padding-top: 80px;
  background-color: white;
}


@media screen and (max-width: 768px) {
  .about-leadership {
    display: none;
    margin-top: 0px;
  }
  .about-certification {
    margin-top: 60px;
    /* margin-top: -200px; */
  }
}
