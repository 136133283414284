.IAM-bg {
    background-color: black;
    margin-top: 40px;
}

.IAM-Intro-bg {
    background-color: white;
    padding-bottom: 30px;
    width: 1200px
}

.IAM-grid-bg {
    margin-top: 10px;
}

.service-card {
    background: rgb(76 76 76);
    padding: 60px 0px 0px 0px;
    border-radius: 10px;
    width: 335px;
    height: 500px;
    margin: auto 10px auto 10px;
}

.servicesCate-authright-logo {
    margin-top: 50px;
    margin-bottom: 30px;
}



.forgerock-content2 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
}

.forgerock-content3 {
    margin-bottom: 40px;    
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    margin-bottom: 0px;
}

.servicesCate-content4 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:17px;
    line-height: 150%;
    color: #d9d9d9;
    width: 300px;
    margin: 10px auto auto auto;
}

.service-card-title {
    font-size: 23px;
    font-family: 'SFProDisplay-bold',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #fff;
    padding-bottom: 15px;
}

.service-iam-title {
    font-size: 20px !important;
    font-family: 'SFProDisplay-bold',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #fff !;
    padding-bottom: 15px;
}

.service-iam-title2 {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    padding-bottom: 15px;
    color: #fff;
}

.service-iam-title3 {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    color: #fff;
    padding-top: 15px;
}

.serivce-iam-link {
    font-size: 20px;
    font-family: 'NunitoSans-regular',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #1890ff;
}

.service-title {
    color: white;
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: 0px auto 80px auto;
    padding-top: 50px;
}

/* .ant-col ant-col-12 gutter-row1 {
    background-color: red !important;
} */


.forgerock-title {
    height: 400px;
}

.service-img {
    margin-bottom: 24px;
}

.service-integration-card-img {
    margin-top: -24px;
    /* margin-bottom: 5psx; */
}

.service-card-support-img {
    margin-bottom: 24px;
}

@media (max-width: 1100px) {

    .about-color-block{
        margin-left: auto;
    }
    .about-company-title2 {
        padding-top: 50px
    }
}
@media (max-width: 400px) {
    .IAM-bg {
        padding-bottom: 40px;
    }
    .service-card {
        height: 400px;
        padding-top: 40px;
        width: 305px;
    }
    .service-title {
        font-size: 15px;
        padding-top: 30px;
        margin-bottom: 30px;
    }
    .service-card-support-img {
        width: 50px
    }
    .service-integration-card-img {
        width: 80px
    }
    .service-card-title {
        font-size: 18px;
    }
    .servicesCate-content4 {
        font-size: 14px;
        width: 80%

    }
}