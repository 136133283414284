.trust-bg {
  background-color: black;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 100px;
}

.trust-bg2 {
  margin: auto;
}

.turst-title {
  padding-top: 50px;
  color: #fff;
  text-align: center;
  font-size: 50px;
  font-family: "SFProDisplay-Semibold", sans-serif;
}

.turst-title2 {
  color: #fff;
  text-align: center;
  font-size: 50px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  margin-bottom: 50px;
}

.trust-grid-bg {
  display: flex;
  justify-content: center;
}

.trust-color-block {
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
  border-radius: 5%;
  height: 360px;
  width: 260px;
  background: linear-gradient(130deg, #ff7a18, #af002d 41.07%, #319197 76.05%);
}

.trust-black-block {
  margin: auto;
  background-color: black;
  height: 350px;
  width: 250px;
  border-radius: 5%;
}

.trust-color-block-title {
  text-align: center;
  padding-top: 30px;
  /* margin-left: 20px; */
  margin-bottom: -10px;
  font-family: "SFProDisplay-Bold", sans-serif;
  padding-bottom: -5px;
  font-style: normal;
  font-size: clamp(1.8rem, calc(1.8rem + 0.2vw), 3rem);
  font-weight: 800;
  color: white;
}

.trust-color-block-title2 {
  margin-bottom: -12px;
  text-align: center;
  /* margin-left: 20px; */
  font-family: "SFProDisplay-Bold", sans-serif;
  font-style: normal;
  font-size: clamp(1.8rem, calc(1.8rem + 0.2vw), 3rem);
  font-weight: 800;
  color: white;
}

.trust-color-block-title3 {
  text-align: center;
  /* margin-left: 20px; */
  font-family: "SFProDisplay-Bold", sans-serif;
  font-style: normal;
  font-size: clamp(1.8rem, calc(1.8rem + 0.2vw), 3rem);
  font-weight: 800;
  color: white;
}

.trust-color-block-title4 {
  margin-bottom: -12px;
  text-align: center;
  /* margin-left: 17px; */
  font-family: "SFProDisplay-Bold", sans-serif;
  font-style: normal;
  font-size: clamp(1.8rem, calc(1.8rem + 0.2vw), 3rem);
  font-weight: 800;
  color: white;
}

.trust-color-block-served {
  padding-top: 70px;
  text-align: right;
  margin-right: 20px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  font-size: clamp(12px, calc(0.8rem + 0.25vw), 20px);
  font-weight: 300;
  line-height: 1.7;
  color: white;
}

.trust-color-block-served2 {
  text-align: left;
  margin-left: 20px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  font-size: clamp(12px, calc(0.8rem + 0.25vw), 20px);
  font-weight: 300;
  line-height: 1;
  color: #1890ff;
}

.trust-forgerock {
  max-width: 110px;
  max-height: 110px;
  margin-bottom: 34px;
  margin-top: 60px;
  border-radius: 5px;
}

.trust-ping {
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 25px;
  margin-top: 60px;
  border-radius: 5px;
}

@media screen and (max-width: 800px) {
  .trust-color-block {
    margin-left: 50px;
    margin-right: 50px;
    height: 234px;
    width: 170px;
  }

  .trust-black-block {
    height: 224px;
    width: 160px;
    border-radius: 5%;
  }

  .trust-ping {
    width: 70px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .trust-forgerock {
    width: 74px;
    margin-top: 45px;
    margin-bottom: 22px;
  }

  .trust-color-block-title2 {
    margin-bottom: -12px;
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }
  
  .trust-color-block-title3 {
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }

  .trust-grid-bg {
    margin:auto
  }

  .turst-title {
    padding-top: 50px;
    color: #fff;
    text-align: center;
    font-size: 28px;
    font-family: "SFProDisplay-Bold", sans-serif;
  }
}


@media screen and (max-width: 460px) {
  .trust-color-block {
    margin-left: 9px;
    margin-right: 10px;
    height: 234px;
    width: 170px;
  }

  .trust-black-block {
    height: 224px;
    width: 160px;
    border-radius: 5%;
  }

  .trust-ping {
    width: 70px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .trust-forgerock {
    width: 74px;
    margin-top: 45px;
    margin-bottom: 22px;
  }

  .trust-color-block-title2 {
    margin-bottom: -12px;
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }
  
  .trust-color-block-title3 {
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }

  .trust-grid-bg {
    margin:auto
  }

  .turst-title {
    padding-top: 50px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: "SFProDisplay-Bold", sans-serif;
  }
}


@media screen and (max-width: 420px) {
  .trust-color-block {
    margin-left: 9px;
    margin-right: 10px;
    height: 234px;
    width: 170px;
  }

  .trust-black-block {
    height: 224px;
    width: 160px;
    border-radius: 5%;
  }

  .trust-ping {
    width: 70px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .trust-forgerock {
    width: 74px;
    margin-top: 45px;
    margin-bottom: 22px;
  }

  .trust-color-block-title2 {
    margin-bottom: -12px;
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }
  
  .trust-color-block-title3 {
    font-size: clamp(1.5rem, calc(1.8rem + 0.2vw), 1.5rem);
  }

  .trust-grid-bg {
    margin:auto
  }

  .turst-title {
    padding-top: 50px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: "SFProDisplay-Bold", sans-serif;
  }
}