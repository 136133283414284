.advisorCard-container {
  width: 1200px;
  height: 240px;
  /* border:1px solid black; */
  box-shadow: 0px 2px 2.9px rgba(0, 142, 131, 0.05),
    0px 0px 5px rgba(3, 79, 73, 0.1);
  border-radius: 5px;
  display: flex;
  margin: 20px auto;
  padding-right: 15px;
}

.advisorCard-img {
  width: 240px;
  height: 240px;
  margin-right: 20px;
  border-radius: 5px 0px 0px 5px;
}
.advisorCard-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.advisorCard-name {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 24px;
  font-family: "NunitoSans-bold", sans-serif;
}

.advisorCard-title {
  text-align: left;
  font-size: 16px;
  font-family: "NunitoSans-bold", sans-serif;
}

.advisor-linkedin {
  cursor: pointer;
  height: 32px;
  margin-top: 5px;
}
.advisorCard-description {
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
  font-family: "NunitoSans-regular", sans-serif;
}

@media screen and (max-width: 420px) {
  .advisorCard-container {
    width: 164px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    padding: 8px;
  }

  .advisorCard-img {
    width: 150px;
    height: 150px;
    margin: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .advisorCard-name {
    font-size: 16px;
  }

  .advisorCard-title {
    font-size: 13px;
  }

  .advisor-linkedin {
    height: 20px;
    margin-top: 0px;
  }
  .advisorCard-description {
    font-size: 11px;
  }
  .advisorCard-description {
    display: none;
  }
}

@media screen and (min-width: 421px) and (max-width: 768px) {
  .advisorCard-container {
    width: 260px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    padding: 8px;
  }

  .advisorCard-img {
    width: 240px;
    height: 240px;
    margin: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .advisorCard-name {
    font-size: 20px;
  }

  .advisorCard-title {
    font-size: 16px;
  }

  .advisor-linkedin {
    height: 28px;
    margin-top: 0px;
  }
  .advisorCard-description {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .advisorCard-container {
    width: 80%;
    height: auto;
    margin: 10px auto;
    padding: 8px;
  }

  .advisorCard-img {
    width: 240px;
    height: 240px;
    margin: auto;
    margin-right:10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .advisorCard-name {
    font-size: 20px;
  }

  .advisorCard-title {
    font-size: 16px;
  }

  .advisor-linkedin {
    height: 28px;
    margin-top: 0px;
  }
  .advisorCard-description {
    font-size: 12px;
  }
}

