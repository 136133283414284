.subCertCard-number {
  font-size: 60px;
  font-weight: 500;
  font-family: "NunitoSans-bold", sans-serif;
  color: #919293;
  /* color: #919293; */
  margin-bottom: 5px;
}

.subCertCard-certName {
  width: 155px;
  font-family: "NunitoSans-regular", sans-serif;
  font-size: 14px;
}

.subCertCard-img{
  width: 80px;
  margin-bottom: 20px;
  cursor: pointer;
}

.subCertCard-okta-img{
  height: 100px;
  cursor: pointer;
}

@media screen and (max-width:420px)  {
  .subCertCard-number{
    font-size: 40px;
  }
  .subCertCard-img {
    width: 40px;
    margin-bottom: 5px;
  }
  .subCertCard-okta-img{
    height: 50px;
  }
  .subCertCard-certName {
    width: 90px;
    font-size: 11px;
  }
}