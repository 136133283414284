.intro-bg {
  padding-top: 30px;
  background-color: black;
  height: 800px;
  background-image: url("https://p.ipic.vip/4qtdfl.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  min-width: 1250px;
}

.intro-content {
  width: 1200px;
  margin: auto;
}

.company-name-title {
  font-size: 56px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  color: #000000;
  padding-top: 250px;
}

.company-name-content1 {
  font-family: "NunitoSans-semibold", sans-serif;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 10px;
  color: #000000;
  /* text-align: right; */
  /* padding-right: 40px; */
  margin-top: -20px;
}

.company-name-content2 {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 25px;
  line-height: 150%;
  font-weight: 700px;
  color: #000000;
  margin-top: 60px;
}

.gray-lane {
  height: 1px;
  background-color: #eff2f7;
  width: 68%;
  margin: auto;
  margin-bottom: 20px;
}

.gray-lane-intro {
  height: 1px;
  background-color: #eff2f7;
  width: 330px;
  margin: auto;
  margin-bottom: 20px;
}

.intro-text {
  width: 400px;
  text-align: center;
  /* margin: auto auto auto 20%;s */
}

@media screen and (max-width: 1024px) {
  .intro-bg {
    background-image: none;
    background-color:#f0f0f0;
    height: 360px;
    min-width: 200px;
    width: 100%;
    overflow: auto;
  }

  .intro-content {
    width: 360px;
    margin: auto;
  }

  .intro-text {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .company-name-title {
    padding-top: 50px;
    margin-bottom: 20px;
    font-size: 56px;
  }
  .company-name-content1 {
    line-height: 150%;
    margin-bottom: 10px;
    font-size: 18px;
  }
  .company-name-content2 {
    font-size: 25px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 800px) {
  .intro-bg {
    background-image: none;
    background-color:#f0f0f0;
    height: 320px;
    min-width: 200px;
    width: 100%;
    overflow: auto;
  }

  .intro-content {
    width: 360px;
    margin: auto;
  }

  .intro-text {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .company-name-title {
    padding-top: 50px;
    margin-bottom: 20px;
    font-size: 35px;
  }
  .company-name-content1 {
    line-height: 150%;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .company-name-content2 {
    font-size: 20px;
    margin-top: 30px;
  }
}


@media screen and (max-width: 420px) {
  .intro-bg {
    background-image: none;
    background-color:#f0f0f0;
    height: 320px;
    min-width: 200px;
    width: 100%;
    overflow: auto;
  }

  .intro-content {
    width: 360px;
    margin: auto;
  }

  .intro-text {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .company-name-title {
    padding-top: 50px;
    margin-bottom: 20px;
    font-size: 35px;
  }
  .company-name-content1 {
    line-height: 150%;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .company-name-content2 {
    font-size: 20px;
    margin-top: 30px;
  }
}
