.ping-logo {
    margin: auto;
    margin-bottom: 40px;
    margin-top: 80px;
}

.ping-intro-bg {
    background-color: white;
    padding-top: 40px;
    margin-bottom: 30px;
    padding-bottom: 10px;
}

.ping-grid-bg {
    margin-top: -30px;
    margin-bottom: -30px;
}

.ping-content1 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    margin-top: -15px;
}

.ping-content2 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
}

.ping-content3 {
    margin-bottom: 40px;    
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    padding-bottom: 30px;
}

.ping-content4 {
    font-family: 'NunitoSans-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: #d9d9d9;
}

.solution-iam-title2 {
    padding-top: 40px;
    font-family: 'NunitoSans-extraBold',sans-serif;
    font-size: 20px;
    line-height: 150%;
    color: #595959;
    margin-bottom: 15px;
}

.solution-ping-logo {
    /* float: left; */
    margin-top: 70px;
    margin-left: 200px;;
}

.service-iam-title {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #fff;
}

.serivce-iam-link {
    font-size: 20px;
    font-family: 'NunitoSans-regular',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #1890ff;
}

.ant-col ant-col-12 gutter-row1 {
    background-color: red !important;
}


.ping-title {
    height: 400px;
}

.solution-ping-gray-lane2 {
    height: 1px;
    background-color: #d9d9d9;
    width: 370px;
    margin-top: 15px;
    margin-bottom: 30px;
} 

.ping-cert-logo {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 50px;
}

.ping-cert-logo2 {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 80px;
}

.ping-cert-logo3 {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 110px;
}

.ping-cert-logo4 {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 140px;
}

.ping-cert-logo5 {
    margin-top: 30px;
    margin-bottom: 120px;
    margin-left: 180px;
}