.about-intro-bg {
  margin-bottom: 40px;
}

.career-company-title {
  padding-top: 40px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  color: #000;
}

.career-company-title {
  padding-top: 70px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  color: #000;
}

.career-company-title2 {
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  /* color: white; */
}

.about-company-title2 {
  padding-top: 140px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  /* color: white; */
}

.about-values-title {
  padding-top: 65px;
  padding-bottom: 45px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  margin: auto;
  color: white;
}

.about-intro-block {
  height: 500px;
  background-color: white;
}

.about-mission-subtitle {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 20px;
  font-weight: 800;
}

.about-intro-block2 {
  color: black;
  background-color: #ffffff;
  padding-bottom: 80px;
}

.about-intro-block3 {
  height: auto;
  background-color: #000;
  padding-bottom: 50px;
}

.career-company-intro {
  padding-top: 20px;
  font-size: 20px;
  width: 700px;
  font-family: "NunitoSans-regular", sans-serif;
  margin: auto;
  color: black;
}

.about-values {
  font-size: 20px;
  width: 900px;
  font-family: "NunitoSans-regular", sans-serif;
  margin: auto;
  color: white;
}

.career-company-content1 {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 18px;
  line-height: 150%;
  padding-top: 50px;
  /* margin-bottom: 10px; */
  color: #000;
}

.career-company-content2 {
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 18px;
  line-height: 150%;
  color: #000;
}

.gray-lane {
  height: 1px;
  background-color: #eff2f7;
  width: 68%;
  margin: auto;
  margin-bottom: 20px;
}

.about-img1 {
  padding-bottom: 100px;
}

.about-color-block {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 70px;
  margin-left: 120px;
  margin-right: 20px;
  border-radius: 5%;
  height: 360px;
  width: 260px;
  background: linear-gradient(130deg, #ff7a18, #af002d 41.07%, #319197 76.05%);
}

.about-black-block {
  margin: auto;
  background-color: #fff;
  height: 350px;
  width: 250px;
  border-radius: 5%;
  color: #000;
}

.about-color-block-title {
  text-align: left;
  padding-top: 70px;
  margin-left: 20px;
  margin-bottom: 20px;
  font-family: "SFProDisplay-Bold", sans-serif;
  font-style: normal;
  font-size: clamp(1.8rem, calc(1.8rem + 0.2vw), 3rem);
  font-weight: 800;
}

.about-color-block-served {
  padding-top: 20px;
  text-align: left;
  margin-left: 20px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  font-size: clamp(12px, calc(0.8rem + 0.25vw), 20px);
  font-weight: 300;
  line-height: 1.7;
  color: #434343;
}

.about-color-block-served2 {
  text-align: left;
  margin-left: 20px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  font-size: clamp(12px, calc(0.8rem + 0.25vw), 20px);
  font-weight: 300;
  line-height: 1.7;
  color: #000;
}

.about-whoWeAre-title1 {
  padding-top: 60px;
  padding-bottom: 30px;
  font-family: "SFProDisplay-Semibold", sans-serif;
  width: 700px;
  margin: auto;
  color: #000;
  font-size: 40px;
}

.about-whoWeAre-title2 {
  font-family: "SFProDisplay-Semibold", sans-serif;
  color: #000;
  font-size: 70px;
}

.about-whoWeAre-content {
  padding-top: 20px;
  font-size: 22px;
  width: 790px;
  font-family: "NunitoSans-regular", sans-serif;
  margin: auto;
  color: black;
}

.value-description {
  text-align: center;
  width: 850px;
  margin-top: 20px;
  color: rgb(108, 227, 227);
  height: 70px;
  background-color: #000;
  
}
.vale-description-text {
    overflow: auto;
    animation: anim 1.2s;
}
@keyframes anim { 
	0% {opacity: 0;}
	100%{opacity:1;
}
}

.selected-value {
  fill: rgb(108, 227, 227) !important;
  color: rgb(108, 227, 227);
  font-family: "NunitoSans-bold", sans-serif;
}

.unselected-value {
  fill: white;
  color: white;
}

.value-img {
  width: 80px;
  margin: auto;
}

.value-area {
  width: 135px;
  height: 135px;
  padding-top: 26px;
  margin: auto;
}
.value-halo {
  background: radial-gradient(
    closest-side circle,
    rgba(255, 255, 255, 0.509),
    black
  );
}

.value-preview {
  padding: auto;
  margin-bottom: 40px;
}

.about-color-block-served2-t1 {
  color: #1890ff;
}

.about-color-block-served2-t2 {
  color: #f5222d;
}


@media (min-width:400px) and (max-width: 800px) {
  .about-color-block {
    margin: 50px auto auto auto;
    height: 300px;
  }
  .about-black-block {
    height: 290px;
  }
  .about-whoWeAre-title1 {
    margin-top: 10px;
    width: 290px;
  }

  .about-intro-block {
    height: 450px;
    background-color: white;
  }
  
  .about-mission-subtitle {
    font-family: "NunitoSans-bold", sans-serif;
    font-size: 22px;
    font-weight: 800;
  }

  .career-company-intro {
    width: 80%;
    font-size: 15px;
  }
  .about-company-title2 {
    font-size: 18px;
    padding-top: 40px;
  }
  .about-color-block-title {
    font-size: 25px;
  }
  .about-whoWeAre-content {
    font-size: 20px;
    width: 320px;
  }

  .about-values-title {
    padding-top: 35px;
    padding-bottom: 5px;
  }

  .about-values {
    font-size: 16px;
    width: 100%;
  }
  .value-img {
    width: 50px;
    margin: auto;
  }
  .value-area {
    width: 70px;
    height: 70px;
    padding-top: 26px;
    margin: auto;
  }
  .value-description {
    width: 75%;
    margin-top: 0px;
  }
  .about-intro-block3{
    margin-bottom:30px;
  }
}

@media (max-width: 440px) {
  .about-intro-block {
    height: 650px;
    background-color: white;
  }
}

@media (max-width: 400px) {
  .about-color-block {
    margin: 50px auto auto auto;
    height: 300px;
  }
  .about-black-block {
    height: 290px;
  }
  .about-whoWeAre-title1 {
    margin-top: 10px;
    width: 290px;
  }

  .career-company-intro {
    width: 80%;
    font-size: 15px;
  }
  .about-company-title2 {
    font-size: 18px;
    padding-top: 40px;
  }
  .about-color-block-title {
    font-size: 25px;
  }
  .about-whoWeAre-content {
    font-size: 14px;
    width: 320px;
  }

  .about-values-title {
    padding-top: 35px;
    padding-bottom: 5px;
  }

  .about-values {
    font-size: 16px;
    width: 100%;
  }
  .value-img {
    width: 50px;
    margin: auto;
  }
  .value-area {
    width: 70px;
    height: 70px;
    padding-top: 26px;
    margin: auto;
  }
  .value-description {
    width: 75%;
    margin-top: 0px;
  }
  .about-intro-block3{
    margin-bottom:30px;
  }
}
