.App {
  text-align: center;
  background-color: black;
}

.main-content {
  min-height: calc(100vh - 600px);
}

.footer {
  height: 600px;
}
