body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-bg {
  background-color: red;
}

.authright-inner {
  width: 1200px;
  margin: auto;
}


@font-face {
  font-family: 'NunitoSans-regular';
  src: url('./Nunito\ Sans/NunitoSans-Regular.ttf')
}

@font-face {
  font-family: 'NunitoSans-bold';
  src: url('./Nunito\ Sans/NunitoSans-Bold.ttf');
}

@font-face {
  font-family: 'NunitoSans-SemiBold';
  src: url('./Nunito\ Sans/NunitoSans-SemiBold.ttf')
}

@font-face {
  font-family: "normal-italic";
  src: url('./Nunito\ Sans/NunitoSans-Italic.ttf')
}

@font-face {
  font-family: "NunitoSans-Light";
  src: url("./Nunito\ Sans/NunitoSans-Light.ttf")
}

@font-face {
  font-family: "SFProDisplay-Semibold";
  src: url("./Nunito\ Sans/FontsFree-Net-SFProDisplay-Semibold.ttf")
}

@font-face {
  font-family: "SFProDisplay-Bold";
  src: url("./Nunito\ Sans/FontsFree-Net-SFProDisplay-Bold.ttf")
}

@font-face {
  font-family: "SFProDisplay-MediumItalic";
  src: url("./Nunito\ Sans/FontsFree-Net-SFProDisplay-MediumItalic.ttf")
}

@font-face {
  font-family: "SFProDisplay-Regular";
  src: url("./Nunito\ Sans/FontsFree-Net-SFProDisplay-Regular.ttf")
}

@font-face {
  font-family: "SFProDisplay-SemiboldItalic";
  src: url("./Nunito\ Sans/FontsFree-Net-SFProDisplay-SemiboldItalic.ttf")
}


.light {
  font-family: "NunitoSans-Light";
}

.italic {
  font-family: "normal-italic";
}

.semibold {
  font-family: 'NunitoSans-SemiBold' !important;
}

.bold {
  font-family: "NunitoSans-bold" !important;
}

.regular {
  font-family: "NunitoSans-regular" !important;
}