.customerComments-bg {
  background-color: black;
}

.customerComments-title {
  margin: auto;
  padding-top: 70px;
  padding-bottom: 20px;
  font-size: 40px;
  font-family: "SFProDisplay-Bold", sans-serif;
  color: #f5f5f5;
  width: 800px;
}

.customerComments-section{
  margin:auto;
}

.customerComments-content {
  margin: auto;
  padding-bottom: 70px;
  font-size: 18px;
  font-family: "NunitoSans-regular", sans-serif;
  color: #f5f5f5;
  width: 700px;
}

@media screen and (max-width: 400px) {
  .customerComments-bg {
    background-color: white;
    width: 100%;
  }

  .customerComments-title {
    margin: auto;
    padding-top: 60px;
    padding-bottom: 20px;
    font-size: 20px;
    font-family: "SFProDisplay-Bold", sans-serif;
    color: black;
    width: 360px;
  }

  .customerComments-content {
    margin: auto;
    padding-bottom: 60px;
    font-size: 14px;
    font-family: "NunitoSans-regular", sans-serif;
    color: black;
    width: 360px;
  }
}

.customerComments-forgerockCeo-img {
  border-radius: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.customerComments-customer-title {
  margin-top: -40px;
  text-align: left;
  margin-left: 250px;
  font-size: 20px;
  /* font-family: 'NunitoSans-regular'; */
  font-family: "SFProDisplay-Semibold", sans-serif;
  color: #262626;
}

.customerComments-customer-title2 {
  text-align: left;
  margin-left: 250px;
  font-size: 16px;
  /* font-family: 'NunitoSans-regular'; */
  font-family: "SFProDisplay-regular", sans-serif;
  color: #595959;
}

.customer-comments-content {
  width: 500px;
  padding-top: 20px;
  text-align: left;
  font-size: 25px;
  /* font-family: 'NunitoSans-regular'; */
  font-family: "SFProDisplay-MediumItalic", sans-serif;
  color: black;
}

.comments-bg {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  height: 200px;
  width: 550px;
  margin-top: 80px;
  padding-left: -120px;
  backdrop-filter: blur(30px);
  border: 2px solid transparent;
  background-clip: padding-box;
  box-shadow: 10px 10px 10px rgba(45, 55, 68, 0.3);
  line-height: 1.5;
}

.comments-bg2 {
  background: url(../../../img/case-mohawk-img.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 20%;
  height: 200px;
  width: 550px;
  border-radius: 20px;
}

.container-customersComments {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 580px !important;
  /* flex-wrap: wrap; */
  z-index: 1;
  /* background: #161623 !important; */
  background: url("https://images.unsplash.com/photo-1536514498073-50e69d39c6cf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2851&q=80");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.container-customersComments .card-customersComments {
  position: relative;
  width: 280px;
  height: 400px;
  margin: 20px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.container-customersComments
  .card-customersComments
  .content-customersComments {
  padding: 20px;
  text-align: center;
  opacity: 1;
}

.container-customersComments
  .card-customersComments
  .content-customersComments
  h2 {
  position: absolute;
  top: -80px;
  right: 30px;
  font-size: 8em;
  color: rgba(255, 255, 255, 0.05);
  pointer-events: none;
}

.container-customersComments
  .card-customersComments
  .content-customersComments
  h3 {
  font-size: 1.8em;
  color: #000;
  z-index: 1;
}

.container-customersComments
  .card-customersComments
  .content-customersComments
  h4 {
  color: #000;
  z-index: 1;
}

.container-customersComments
  .card-customersComments
  .content-customersComments
  p {
  font-size: 1em;
  color: #141414;
  font-weight: 300;
  font-family: sans-serif;
}

.container-customersComments
  .card-customersComments
  .content-customersComments
  a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  margin-top: 15px;
  background: #fff;
  color: #000;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 500;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.comment-paragraph {
  margin-top: 30px;
}

.comments-logo {
  width: 130px;
  height: auto;
  margin-top: -7px;
}

.comments-logo-homedepot {
  width: 180px;
  margin-top: -10px;
  margin-bottom: 20px;
}

.equifax-comments-scope {
  width: 80%;
  margin: auto;
}

.comments-logo-equifax {
  width: 260px;
  margin-bottom: -10px;
}

.comments-logo-mohawk {
  width: 160px;
  margin-top: 15px;
  margin-bottom: 40px;
}

.tiltTest {
  padding: 0px !important;
  margin: 0px !important;
  background: white !important;
}


@media screen and (max-width: 1200px) {
  .container-customersComments .card-customersComments {
    width: 210px;
    height: 300px;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h3 {
    margin-top: -10px;
    font-size: 1.2em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h4 {
    font-size: 0.7em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    p {
    font-size: 0.66em;
    color: #141414;
    font-weight: 300;
    font-family: sans-serif;
  }

  .comments-logo {
    width: 87px;
    height: auto;
    margin-top: -10px;
  }

  .comments-logo-homedepot {
    width: 121px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  
  .comments-logo-equifax {
    width: 174px;
    margin-top: -35px;
    margin-bottom: -10px;
  }
  
  .comments-logo-mohawk {
    width: 107px;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .comment-paragraph {
    margin-top: 10px;
  }

}


@media screen and (max-width: 850px) {
  .customerComments-bg {
    background-color: white;
  }
  .container-customersComments .card-customersComments {
    width: 165px;
    height: 230px;
    margin: auto;
    margin-top: 20px;
  }

  .container-customersComments {
    height: 400px !important;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h3 {
    margin-top: -10px;
    font-size: 1.2em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h4 {
    font-size: 0.7em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    p {
    font-size: 0.66em;
    color: #141414;
    font-weight: 300;
    font-family: sans-serif;
  }

  .comments-logo {
    width: 87px;
    height: auto;
    margin-top: -10px;
  }

  .comments-logo-homedepot {
    width: 121px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  
  .comments-logo-equifax {
    width: 174px;
    margin-top: -35px;
    margin-bottom: -10px;
  }
  
  .comments-logo-mohawk {
    width: 107px;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .comment-paragraph {
    margin-top: 10px;
  }

  .customerComments-title {
    font-size: 28px;
    width: 100%;
    color: black;
  }

  .customerComments-content {
    margin: auto;
    padding-bottom: 70px;
    font-size: 18px;
    width: 90%;
    color: black;
  }
}


@media screen and (max-width: 440px) {
  .container-customersComments .card-customersComments {
    width: 165px;
    height: 230px;
    margin: auto;
    margin-top: 20px;
  }

  .container-customersComments {
    height: 580px !important;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h3 {
    margin-top: -10px;
    font-size: 1.2em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    h4 {
    font-size: 0.7em;
    color: #000;
    z-index: 1;
  }

  .container-customersComments
    .card-customersComments
    .content-customersComments
    p {
    font-size: 0.66em;
    color: #141414;
    font-weight: 300;
    font-family: sans-serif;
  }

  .comments-logo {
    width: 87px;
    height: auto;
    margin-top: -10px;
  }

  .comments-logo-homedepot {
    width: 121px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  
  .comments-logo-equifax {
    width: 174px;
    margin-top: -35px;
    margin-bottom: -10px;
  }
  
  .comments-logo-mohawk {
    width: 107px;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .comment-paragraph {
    margin-top: 10px;
  }

  .customerComments-title {
    font-size: 20px;
    width: 100%;
  }

  .customerComments-content {
    margin: auto;
    padding-bottom: 70px;
    font-size: 13px;
    width: 90%;
  }

}



