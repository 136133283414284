.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.about-certification {
  width: 100%;
  padding-top:5px;
}

.certification-bg {
  /* margin-top: 400px; */
  height: auto;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.certification-title {
  padding: 0px 0px 50px 0px;
  font-size: 40px;
  font-family: "NunitoSans-bold", sans-serif;
  color: black;
}

.certification-cards {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;
  align-items: center;
  /* grid-template-columns: 50% 50%;
  grid-template-rows: 33.3% 33.3% 33.3%; */
}


@media screen and (max-width:768px) and (min-width:421px) {
  .certification-bg {
    background-color: black;
    /* padding-top: -400px; */
  }
  .certification-cards{
    width: 700px;
  }
  .certification-title {
    color: white;
    margin-top: 40px;
  }
}

@media screen and (max-width:420px)  {
  .certification-bg {
    background-color: black;
    /* padding-top: -400px; */
  }
  .certification-cards{
    width: 380px;
  }
  .certification-title {
    padding: 50px 0px 50px 0px;
    font-size: 40px;
    font-family: "NunitoSans-bold", sans-serif;
    color: white;
  }
}

@media screen and (max-width:1024px) and (min-width:769px) {
  .certification-bg {
    /* padding-top: 400px; */
    background-color: black;
  }
  .certification-cards{
    width: 100%;
  }
  .certification-title {
    color: white;
    margin-top: 40px;
  }
}


@media screen and (min-width:770px) {
  .certification-bg {
    /* margin-top: 350px; */
  }
}
