.IAM-bg {
    background-color: black;
    margin-top: 40px;
}

.forgerock-serice-banner {
    background-color: white;
    padding-bottom: 170px;
    padding-top: 100px;
}

.IAM-grid-bg {
    margin-top: 10px;
}

.forgerock-content1 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    margin-top: -35px;
    color: black;
}

.forgerock-content2 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
}

.forgerock-content3 {
    margin-bottom: 40px;    
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: black;
    margin-bottom: 0px;
}

.forgerock-content4 {
    font-family: 'SFProDisplay-regular',sans-serif;
    font-size:18px;
    line-height: 150%;
    color: #d9d9d9;
}

.service-iam-title {
    font-size: 40px !important;
    font-family: 'SFProDisplay-bold',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #fff !;
    padding-bottom: 15px;
}

.service-iam-title2 {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    padding-bottom: 15px;
    color: #fff;
}

.service-iam-title3 {
    font-size: 40px;
    font-family: 'NunitoSans-bold',sans-serif;
    margin: auto;
    color: #fff;
    padding-top: 15px;
}

.serivce-iam-link {
    font-size: 20px;
    font-family: 'NunitoSans-regular',sans-serif;
    margin: auto;
    margin-top: 10px;
    color: #1890ff;
}



.forgerock-title {
    height: 400px;
}

.service-img {
    margin-bottom: 14px;
}

.service-forgerock-integration-img {
    margin-top: -25px;
    margin-bottom: -10px;
}

.service-forgerock-support-img {
    margin-bottom: 17px;
}