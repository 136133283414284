.business-bg2 {
  background-color: #fafafa;
  margin: auto;
  padding-top: 80px;
}

.businessOutcome-intro {
  height: 1000px;
  width: 1200px;
  margin: auto;
}

.businessOutcome-title1 {
  padding-top: 100px;
  font-size: 80px;
  font-family: "SFProDisplay-Bold", sans-serif;
  text-align: center;
  color: black;
}

.businessOutcome-title2 {
  position: relative;
  margin-top: 120px !important;
  font-size: 60px;
  font-family: "SFProDisplay-Bold", sans-serif;
  text-align: center;
  margin: 2% 10%;
  color: black;
  z-index: 1;
}

.businessOutcome-underline-img {
  position: relative;
  margin-top: -160px;
  margin-left: -350px;
}

.businessOutcome-card {
  width: 1200px;
  margin: auto;
  margin-top: -100px;
}

.business-bg {
  background-color: black;
}

.customer-logo-list {
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: 20px;
  margin-top: 100px;
}

.customer-logo {
  max-width: 250px;
  max-height: 165px;
  margin-bottom: 50px;
}

svg,
#customer-logo {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 375px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 10px;
    margin-top: 10px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 360px;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 35px;
    padding-top: 60px;
  }

  .businessOutcome-title2 {
    margin-top: 45px !important;
    font-size: 25px;
  }

  .businessOutcome-underline-img {
    width: 70px !important;
    margin-left: 55px;
    bottom: 500px;
  }
}


@media screen and (min-width: 376px) and (max-width: 390px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 10px;
    margin-top: 10px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 360px;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 35px;
    padding-top: 60px;
  }

  .businessOutcome-title2 {
    font-size: 25px;
    margin-top: 45px !important;
  }

  .businessOutcome-underline-img {
    width: 70px !important;
    margin-left: 55px;
    bottom: 678px;
  }
}


@media screen and (min-width: 411px) and (max-width: 429px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 10px;
    margin-top: 10px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 360px;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 35px;
    padding-top: 60px;
  }

  .businessOutcome-title2 {
    font-size: 25px;
    margin-top: 45px !important;
  }

  .businessOutcome-underline-img {
    width: 70px !important;
    margin-left: 55px;
    bottom: 730px;
  }
}

@media screen and (min-width: 430px) and (max-width: 459px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 10px;
    margin-top: 10px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 360px;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 35px;
    padding-top: 60px;
  }

  .businessOutcome-title2 {
    margin-top: 45px !important;
    font-size: 25px;
  }

  .businessOutcome-underline-img {
    width: 70px !important;
    margin-left: 53px;
    bottom: 765px;
  }
}

@media screen and (min-width: 760px) and (max-width: 770px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 10px;
    margin-top: 20px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 80%;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 60px;
    padding-top: 100px;
  }

  .businessOutcome-title2 {
    font-size: 50px;
    margin-top: 45px !important;
  }

  .businessOutcome-underline-img {
    width: 140px !important;
    bottom: 820px;
    margin-left: 245px;
  }
}

@media screen and (min-width: 771px) and (max-width: 830px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 10px;
    margin-top: 20px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 80%;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 60px;
    padding-top: 100px;
  }

  .businessOutcome-title2 {
    font-size: 50px;
    margin-top: 45px !important;
  }

  .businessOutcome-underline-img {
    width: 140px !important;
    bottom: 973px;
    margin-left: 235px;
  }
}

@media screen and (min-width: 831px) and (max-width: 1200px) {
  .customer-logo-list {
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 10px;
    margin-top: 40px;
  }
  .businessOutcome-intro {
    height: auto;
    width: 80%;
    margin: auto;
  }
  .businessOutcome-title1 {
    font-size: 60px;
    padding-top: 100px;
  }

  .businessOutcome-title2 {
    font-size: 50px;
  }

  .businessOutcome-underline-img {
    width: 140px !important;
    bottom: 1085px;
    margin-left: 88px;
  }
}