.clients-bg2 {
  min-width: 1200px;
  border: none;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  /* position: relative; */
  height: 160px;
  background-color: black;
  margin-top: 65px;
}

.clients-bg {
  display: inline-block;
  position: relative;
  padding-right: 0px;
  animation: logo 35s linear infinite;
  white-space: nowrap;
  margin-top: -74px;
  /* min-width: 1200px; */
}

.hsbc-logo {
  margin-top: -20px;
  width: 120px;
  height: 160px;
  margin-left: 25px;
  margin-right: 25px;
}

.blue-cross-logo {
  margin-top: -28px;
  width: 73px;
  height: 100px;
  margin-left: 25px;
  margin-right: 25px;
}

.deloitte-logo {
  margin-top: -20px;
  width: 120px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.toyota-logo {
  margin-top: -20px;
  width: 140px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.homeDepot-logo {
  margin-top: -20px;
  width: 65px;
  height: 160px;
  margin-left: 25px;
  margin-right: 25px;
}

.macy-logo {
  margin-top: -20px;
  width: 180px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.equifax-logo {
  margin-top: -20px;
  width: 120px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.mohawk-logo {
  margin-top: -20px;
  width: 120px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.lowes-logo {
  margin-top: -20px;
  width: 120px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

.pearson-logo {
  margin-top: -20px;
  width: 120px;
  height: 250px;
  margin-left: 25px;
  margin-right: 25px;
}

@-webkit-keyframes logo {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes logo {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 1024px) {
  .clients-bg2 {
    min-width: 1024px;
    border: none;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
    /* position: relative; */
    height: 140px;
    background-color: black;
    margin-top: 45px;
  }
}


@media screen and (max-width: 800px) {
  .clients-bg2 {
    display: none;
  }
}
