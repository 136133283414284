.leadCard-container {
  width: 1200px;
  height: 280px;
  /* border:1px solid black; */
  /* box-shadow: 0px 2px 2.9px rgba(143, 145, 144, 0.05),
    0px 0px 5px rgba(143, 145, 144, 0.1); */
  border-radius: 5px;
  display: flex;
  margin: 60px auto;
  padding-right: 10px;
  background-color: white;
}

/* .leadCard-container:hover {
    box-shadow: 0px -0.8px 6px rgba(143, 145, 144, 0.1),
      0px 6.4px 20px rgba(143, 145, 144, 0.2);
  } */
.leadCard-img {
  width: 270px;
  margin-right: 20px;
  border-radius: 5px;
}
.leadCard-info {
  /* height: 380px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}
.leadCard-name {
  display: flex;
  justify-content: space-between;
  font-size: 26px;
  font-family: "NunitoSans-bold", sans-serif;
  color: black;
}
.leadCard-title {
  text-align: left;
  font-size: 18px;
  font-family: "NunitoSans-bold", sans-serif;
  color: black;
}
.leadCard-linkedin {
  cursor: pointer;
  height: 35px;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
}
.leadCard-description {
  text-align: left;
  font-size: 15px;
  margin-top: 10px;
  font-family: "NunitoSans-regular", sans-serif;
  color: black;
}

@media screen and (max-width: 420px) {
  .leadCard-container {
    width: auto;
    height: auto;
    margin: 10px auto;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .leadCard-img {
    width: 200px;
    height: 200px;
    margin-right: 0px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .leadCard-name {
    font-size: 15px;
  }
  .leadCard-title {
    font-size: 13px;
  }
  .leadCard-linkedin {
    height: 22px;
    margin-right: 0px;
    margin-left: 50px;
  }
  .leadCard-description {
    font-size: 11px;
    margin-top: 10px;
    display: none;
  }
}

@media screen and (min-width: 420px) and (max-width: 768px){
  .leadCard-container {
    width: auto;
    height: auto;
    margin: 10px auto;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .leadCard-img {
    width: 260px;
    height: 260px;
    margin-right: 0px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .leadCard-name {
    font-size: 20px;
  }
  .leadCard-title {
    font-size: 16px;
  }
  .leadCard-linkedin {
    height: 28px;
    margin-right: 0px;
    margin-left: 50px;
  }
  .leadCard-description {
    font-size: 11px;
    margin-top: 10px;
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px){
  .leadCard-container {
    width: 82%;
    height: auto;
    margin: 10px auto;
    padding: 8px;
  }
  .leadCard-img {
    width: 32%;
    height: 32%;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .leadCard-name {
    font-size: 20px;
  }
  .leadCard-title {
    font-size: 16px;
  }
  .leadCard-linkedin {
    height: 28px;
    margin-right: 0px;
    margin-left: 50px;
  }
  .leadCard-description {
    font-size: 11.5px;
    margin-top: 10px;
  }
}
