@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,600");

:root {
  --background: black;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.header-bg {
  margin: 0;
  background: black;
  font-family: "NunitoSans-bold", sans-serif;
  font-size: 17px;
  height: 80px;
}

.content {
  height: 200vh;
  background-image: url(//unsplash.it/1000/1000);
  background-color: #333;
  background-blend-mode: multiply;
  background-size: cover;
  display: grid;
  place-items: center;
}

header {
  background: var(--background);
  text-align: center;
  position: fixed;
  z-index: 999;
  width: 100%;
}

/* 控制汉堡icon */
.nav-toggle-label {
  position: absolute;
  top: 4px;
  right: 25px;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;
}

/* 汉堡三条线 */
.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
  display: block;
  background: white;
  height: 2px;
  width: 1.7em;
  border-radius: 2px;
  position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
  content: "";
  position: absolute;
}

.nav-toggle-label span::before {
  bottom: 7px;
}

.nav-toggle-label span::after {
  top: 7px;
}

nav {
  position: absolute;
  text-align: left;
  top: 70%;
  left: 0px;
  background: black;
  width: 100%;
}

nav ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

nav li {
  margin-bottom: 1em;
  margin-left: 1em;
}

nav a {
  color: #a7a7ab;
  margin-left: 0.8em;
  text-decoration: none;
  font-size: 17px;
  font-family: "NunitoSans-bold", sans-serif;
}

nav a:hover {
  color: #fff;
}

.nav-active {
  color: #fff;
}

.headerLogo {
  margin-top: 27px;
}

.wide-nav-link { 
  flex-grow: 1;  /*Change the width of nav bar font*/
  white-space: nowrap; 
}

@media screen and (min-width: 1024px) {
  .nav-toggle-label {
    display: none;
  }

  nav li {
    margin-left: 1.5em;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 769px) {
  .nav-toggle-label {
    display: none;
    margin-right: 20px;
  }

  header {
    display: grid;
    /* display: flex; */
    grid-template-columns: 1fr 0 minmax(550px, 1fr) 2fr;
  }

  nav {
    position: relative;
    text-align: left;
    transition: none;
    transform: scale(1, 1);
    top: initial;
    left: initial;
    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  nav ul {
    display: flex;
  }

  nav li {
    margin-left: 0.5em;
    margin-bottom: 10px;
  }

  nav a {
    opacity: 1;
    position: relative;
  }

  nav a::before {
    content: "";
    display: block;
    /* height: 5px; */
    background: #f0f0f0;
    position: absolute;
    top: -0.75em;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transition: transform ease-in-out 250ms;
  }

  nav a:hover::before {
    transform: scale(1, 1);
  }
}

.header-nav {
  padding-top: 20px;
  height: auto;
  background-color: black;
}


@media screen and (min-width: 1000px) {
  .headerLogo {
    margin-left: 40px;
  }

  

  .wide-nav-link { 
    flex-grow: 1;  /*Change the width of nav bar font*/
    white-space: nowrap; 
  }
}


