.footer-bg {
  background-color: black;
  padding-bottom: 20px;
}

.footer-block {
  margin-left: 23%;
}

.gray-lane-footer {
  height: 1px;
  background-color: #595959;
  /* width: 1200px; */
  margin: auto;
  margin-bottom: 20px;
}

.footer-title {
  font-size: 18px;
  font-family: "NunitoSans-bold", sans-serif;
  color: #eff2f7;
  text-align: left;
  margin-bottom: 5px;
  margin-left: 13px;
}

.footer-title2 {
  font-size: 18px;
  font-family: "NunitoSans-bold", sans-serif;
  color: #eff2f7;
  text-align: left;
  margin-bottom: 5px;
  margin-left: 130px;
}

.footer-link {
  margin-bottom: 5px;
  color: #8c8c8c;
  margin-top: 5px;
  margin-left: 14px;
  font-size: 18px;
  font-family: "NunitoSans-regular", sans-serif;
  text-align: left;
  color: #8c8c8c;
}

.footer-link a {
  text-decoration: none !important;
  color: inherit;
}

.footer-link a:hover {
    color: #1890ff;
  }

.footer-content {
  font-size: 18px;
  font-family: "NunitoSans-regular", sans-serif;
  margin-bottom: 5px;
  color: #8c8c8c;
  text-align: left;
  margin-top: 5px;
  margin-left: 14px;
}

.footer-content2 {
  font-size: 18px;
  font-family: "NunitoSans-regular", sans-serif;
  margin-bottom: 5px;
  color: #8c8c8c;
  text-align: left;
  margin-top: 5px;
}

.footer-divi-img {
  float: left;
  margin-right: 10px; 
  margin-top: 4px; 
  height: 20px;
}

.inner-footer {
  width: 1200px;
  margin: auto;
}

.footer-address {
  margin-left: 76px;
}

.footer-linkedin {
  display: flex;
  margin-left: 14px;
  cursor:pointer;
}

@media screen and (max-width: 768px) {
  .footer-block {
    margin-left: 5% !important;
  }

  .footer-content {
    font-size: 14px;
    font-family: "NunitoSans-regular", sans-serif;
    margin-bottom: 5px;
    margin-right: 5px;
    color: #8c8c8c;
    text-align: left;
    margin-top: 5px;
    margin-left: 14px;
  }

  .footer-content2 {
    font-size: 14px;
    font-family: "NunitoSans-regular", sans-serif;
    margin-bottom: 5px;
    color: #8c8c8c;
    text-align: left;
    margin-top: 5px;
  }

  .footer-link {
    margin-bottom: 5px;
    color: #8c8c8c;
    margin-top: 5px;
    margin-left: 14px;
    font-size: 14px;
    font-family: "NunitoSans-regular", sans-serif;
    text-align: left;
    color: #8c8c8c;
  }
  
  .footer-title {
    font-size: 14px;
    font-family: "NunitoSans-bold", sans-serif;
    color: #eff2f7;
    text-align: left;
    margin-bottom: 5px;
    margin-left: 13px;
  }
}
