:root {
    --clr-neutral-900: #141414;
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-accent-400: hsl(142, 90%, 61%);
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.card-bg {
    margin-top: 60px;
    min-height: 80vh;
    place-items: center;
    line-height: 1.6;
    /* background: black; */
    text-align: left;
    max-width: 1600px;
    margin: auto;
    /* background-color: black; */
}
.card {
    color: var(--clr-neutral-100);
    background-size: cover;
    padding: 10rem 0 0;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 500ms ease;
    width: 380px;
    margin: auto 10px auto 10px;
}

.card:hover,
.card:focus-within {
    transform: scale(1.05);
}

.card-content {
    --padding: 1.5rem;
    padding: 0 0 1.5rem 1.5rem;
    background: linear-gradient(
        /* hsl(0 0% 0% / 0.5), */
        hsl(0 0% 0% / 0.8),
        hsl(20 0% 0% / 0.40) 60%,
        hsl(100 0% 0% / 1) 
    );
} 

.card-title {
    /* margin-top : -50px; */
    position: relative;
    width: max-content;
    color: var(--clr-neutral-100);
} 

.card-title::after {
    content: "";
    position: absolute;
    height: 4px;
    left: calc(var(--padding) * -1);
    bottom: -2px;
    width: calc(100% + var(--padding));
    background: var(--clr-accent-400);
    transform-origin: left;
    transition: transform 500ms ease;
} 

.card:hover .card-title::after,
.card:focus-within .card-title::after {
    transform: scaleX(1);
}

.card-body {
    color: rgb(255 255 255 / 0.85);
    margin-top: 7px;
    margin-bottom: 7px;
    height: 70px;
}

@media (hover) {
    .card-content {
        transform: translateY(65%);
        transition: transform 500ms ease;
    }

    .card:hover .card-content,
    .card:focus-within .card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .card:focus-within .card-content {
        transition-duration: 0ms;
    }

    .card-content > *:not(.card-title) {
        opacity: 0;
        transition: opacity 200ms linear;
    }

    .card:hover .card-content > *:not(.card-title),
    .card:focus-within .card-content > *:not(.card-title) {
        opacity: 1;
        transition-delay: 1000ms;
    }

    .card-title::after {
        transform: scaleX(0);
    }
}

.button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: var(--clr-neutral-900);
    background-color: var(--clr-accent-400);
    padding: 0.5em 1.25em;
    border-radius: 0.25em;
}

.button:hover,
.button:focus {
    background-color: var(--clr-neutral-100);

}

.card-grid-case-study-title {
    padding-top: 50px;
    color: #fff;
    text-align: center;
    font-size: 50px;
    font-family: 'SFProDisplay-semibold',sans-serif;
}

.card-grid-title {
    padding-top: 20px;
    color: #f0f0f0;
    text-align: center;
    font-size: 20px;
    font-family: 'SFProDisplay-regular',sans-serif;
    padding-bottom: 50px;
}

p{
    margin-bottom: 0px !important;
}

@media (max-width: 420px) {
    .card-bg {
        margin-top:0px
    }
    .card-grid-case-study-title {
        font-size: 20px;
    }
    .card-grid-title {
        font-size: 13px;
    }
    .card {
        width:305px;
    }
    .card-title {
        font-size: 15px;
        padding-bottom:7px;
        padding-top: 10px;
    }
    .card-body {
        width: 100%
    }
    .button  {
        width: 85px;
        font-size: 10px;
    }
}